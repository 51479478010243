import styled from 'styled-components';

export const Container = styled.div`
  .background_file {
    background-color: ${({ primaryColor }) => `${primaryColor} !important`};
    border: 1px solid;
    border-color: ${({ secondaryColor }) => `${secondaryColor} !important`};

    padding: 8px 16px;
    border-radius: 8px;

    display: flex;
    gap: 13px;
    align-items: center;
  }

  p {
    color: ${({ secondaryColor }) => `${secondaryColor} !important`};
  }
`;
