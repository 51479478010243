import styled from 'styled-components';
import { getFooterFontSize, getFooterPading } from '../../utils/getSizes';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ webchatType, hasFooter }) =>
    webchatType === 'fullscreen' && !hasFooter && '8px'};
  padding: ${({ fontSize, webchatType }) =>
    getFooterPading(fontSize, webchatType)};
  position: ${({ webchatType }) => webchatType === 'webchat' && 'absolute'};
  bottom: ${({ webchatType }) => webchatType === 'webchat' && '0'};
  box-sizing: border-box;

  span {
    font-size: ${({ fontSize }) => getFooterFontSize(fontSize)};
  }

  a {
    text-decoration: none;
    color: #979aa5;
  }
`;
