export const executeRequest = async (props) => {
  const { url, method = 'POST', payload = {} } = props;
  if (!url) return;

  const data = await fetch(url, {
    method,
    body: JSON.stringify(payload),
  }).catch((error) => console.log('Error to execute request: ', error));

  return data;
};
