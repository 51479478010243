import { useContext } from 'react';
import { SettingsContext } from '../Context/SettingsContext';

const LocalStorageUtils = () => {
  const { bot, customSettings } = useContext(SettingsContext);
  const { webchatType } = customSettings;
  const { _id: botId } = bot;

  const setOnLocalStorage = (module, data, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    return localStorage.setItem(`boteria-${module}-${currentBotId}`, data);
  };

  const getOnLocalStorage = (module, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    return localStorage.getItem(`boteria-${module}-${currentBotId}`);
  };

  const removeOnLocalStorage = (modules, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    const arrModules = Array.isArray(modules) ? modules : [modules];
    return arrModules.forEach((module) => {
      localStorage.removeItem(`boteria-${module}-${currentBotId}`);
    });
  };

  return {
    setOnLocalStorage,
    getOnLocalStorage,
    removeOnLocalStorage,
  };
};

export default LocalStorageUtils;
