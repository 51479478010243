import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import cc from 'classcat';
import uniqid from 'uniqid';

import * as S from './styles';

const Checkbox = forwardRef(
  (
    { value, label, color, onChange, complementLabel, testId, ...rest },
    ref
  ) => {
    const id = uniqid(`checkbox__`);

    return (
      <S.ContainerCheckbox {...rest}>
        <span>{label}</span>
        <input
          type="checkbox"
          id={id}
          value={value}
          onChange={onChange}
          ref={ref}
          {...rest}
          data-testid={null}
        />
        <span className={cc([color, 'checkmark'])} />
      </S.ContainerCheckbox>
    );
  }
);

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'success', 'danger']),
  className: PropTypes.string,
  complementLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  color: 'primary',
  className: '',
  complementLabel: '',
};

export default Checkbox;
