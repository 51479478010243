import styled from 'styled-components';

export const Container = styled.div``;

export const ChannelsBar = styled.div`
  position: fixed;
  bottom: 21px;
  right: 30px;

  width: ${({ iconSize }) => `${iconSize + 16}px`};
  padding: ${({ channelsBarIsOpen }) => !channelsBarIsOpen && '16px 0 0 0'};

  display: flex;
  justify-content: center;

  border-radius: 500px;

  background-color: ${({ background }) => background};
  box-shadow: ${({ background }) =>
    background && '0px 8px 24px -4px #18274b14'};
  box-shadow: ${({ background }) =>
    background && '0px 6px 12px -6px #18274b1f'};

  display: flex;
  flex-direction: column;
  gap: 8px;

  .widget-space {
    min-height: ${({ iconSize, background }) =>
      background ? `${iconSize}px` : `${iconSize}px`};
  }

  transition: all 0.3s ease-out;
  overflow: hidden;
  height: ${({ iconSize }) => `${iconSize}px`};

  height: ${({ channelsBarIsOpen, channelsLength, iconSize }) =>
    channelsBarIsOpen &&
    `${iconSize + 16 + channelsLength * (iconSize + 8)}px`};

  .icon-enter {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  .icon-enter-active {
    opacity: 1;
  }

  .icon-exit {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  .icon-exit-active {
    opacity: 0;
  }

  .icon-exit-done {
    opacity: 0;
  }
`;

export const WidgetButton = styled.div``;
