import React from 'react';

import { useSettingsContext } from '../../Context/SettingsContext';
import Typography from '../Typography';
import CheckBox from '../CheckBox/index';
import Button from '../Button';

import * as S from './styles';

const Settings = ({ setSettingsIsOpen }) => {
  const { fontSize, setFontSize } = useSettingsContext();

  const getLabel = (size) => {
    const getValue = () => {
      if (size === 'normal_size') return '100%';
      if (size === 'large_size') return '150%';
      if (size === 'extra_large_size') return '200%';
    };
    return (
      <div className="checkbox_options_row">
        <Typography variant="body-small" className={size}>
          {getValue(size)} -&nbsp;
        </Typography>
        <Typography variant="body-small" className={size}>
          Exemplo
        </Typography>
      </div>
    );
  };
  return (
    <S.Container fontSize={fontSize}>
      <Button
        variant="outline"
        size="small"
        fullWidth
        onClick={() => setSettingsIsOpen(false)}
        className="mb-8 close_settings"
      >
        <Typography
          variant="caption-default"
          color="primary-800-light"
          data-testid="close-settings"
        >
          Fechar configurações
        </Typography>
      </Button>
      <Typography variant="body-default-strong" className="mb-4">
        Acessibilidade
      </Typography>
      <Typography variant="body-small-strong" className="mb-8">
        Tamanho da fonte
      </Typography>
      <div className="checkbox_options">
        <CheckBox
          key="100%"
          value="100%"
          checked={fontSize === 'normal_size'}
          label={getLabel('normal_size')}
          onChange={() => setFontSize('normal_size')}
          data-testid="normal_size"
        />
        <CheckBox
          key="150%"
          value="150%"
          checked={fontSize === 'large_size'}
          label={getLabel('large_size')}
          onChange={() => setFontSize('large_size')}
          data-testid="large_size"
        />
        <CheckBox
          key="200%"
          value="200%"
          checked={fontSize === 'extra_large_size'}
          label={getLabel('extra_large_size')}
          onChange={() => setFontSize('extra_large_size')}
          data-testid="extra_large_size"
        />
      </div>
    </S.Container>
  );
};

export default Settings;
