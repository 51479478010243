import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: ${({ iconSize }) => `${iconSize + 34}px`};
  right: ${({ isChannelsBar, iconSize }) =>
    isChannelsBar ? `${iconSize + 60}px` : '36px'};

  @media (max-width: 450px) {
    right: inherit;
    left: 4px;
  }

  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 999;

  -webkit-box-shadow: 0px 10px 10px 42px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 10px 10px 42px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 10px 42px 42px rgba(0, 0, 0, 0.02);
  border-radius: 24px;

  .webchat_fixed {
    background-color: #ffffff;
    border-radius: 30px 30px 24px 24px;
    position: relative;
  }

  .webchat_closed {
    animation: close-webchat 0.4s forwards;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  @keyframes close-webchat {
    0% {
      opacity: 1;
      height: 497px;
      width: 350px;
    }

    25% {
      opacity: 0.1;
    }

    to {
      opacity: 0;
      width: 0px;
      height: 100px;
      z-index: -1;
      bottom: 250px;
      right: 90px;
      position: fixed;
    }
  }

  .webchat_opened {
    animation: open-webchat 0.5s forwards;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  @keyframes open-webchat {
    0% {
      opacity: 0;
      width: 0px;
      height: 100px;
      bottom: ${({ iconSize }) => `${iconSize + 40}px`};
      right: 30px;
      position: fixed;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.1;
    }

    to {
      opacity: 1;
      height: 497px;
      width: 350px;
    }
  }
`;

export const ImageFull = styled.img`
  object-fit: cover;
  max-width: 90vw;
  max-height: 90vh;
  margin: 10px;
  cursor: pointer;
`;
