export const formatVariables = (variables) => {
  if (variables) {
    if (typeof variables === 'object') {
      return variables;
    }
    try {
      return JSON.parse(variables);
    } catch {
      console.log('Error on params format');
      return {};
    }
  }
  return {};
};

export const treatMediaToLocalStorage = (message) => {
  if (message?.type !== 'TEXT') {
    return {
      type: message?.type,
      from: message?.from,
      isStorageUserMedia: true,
      message: '',
      time: message?.time,
    };
  }
  return message;
};
