import React, { memo } from 'react';
import cc from 'classcat';

import { useSettingsContext } from '../../Context/SettingsContext';
import TimeOnMessage from './components/TimeOnMessage';
import DeliveredFile from './components/DeliveredFile';

import * as S from './styles';

export default (MessageComponent) => {
  const MessageWrapper = (props) => {
    const { message, channel, focus } = props;
    const { fontSize, customSettings } = useSettingsContext();
    const { layout } = customSettings;
    const {
      backgroundBotColor,
      textBotColor,
      backgroundUserColor,
      textUserColor,
    } = layout;

    return (
      <S.Message
        fontSize={fontSize}
        backgroundBotColor={backgroundBotColor}
        textBotColor={textBotColor}
        backgroundUserColor={backgroundUserColor}
        textUserColor={textUserColor}
        className={cc([
          {
            'message-user': message?.from === 'user',
            'message-bot': message?.from === 'bot',
            'message-user--webchat':
              message?.from === 'user' && channel === 'webchat',
            'message-bot--webchat':
              message?.from === 'bot' && channel === 'webchat',
            'message-user--whatsapp':
              message?.from === 'user' && channel === 'whatsapp',
            'message-bot--whatsapp':
              message?.from === 'bot' && channel === 'whatsapp',
          },
        ])}
        focus={focus}
        data-testid={message.message}
      >
        {message?.isStorageUserMedia ? (
          <DeliveredFile
            primaryColor={backgroundUserColor}
            secondaryColor={textUserColor}
          />
        ) : (
          <MessageComponent {...props} />
        )}
        {message.type !== 'TYPING' && (
          <TimeOnMessage
            time={message?.time}
            color={message?.from === 'user' ? textUserColor : textBotColor}
          />
        )}
      </S.Message>
    );
  };

  return memo(MessageWrapper);
};
