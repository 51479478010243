import { useEffect, useState } from 'react';

import { useChatContext } from '../Context/ChatContext';
import { useMessengerContext } from '../Context/MessengerContext';

import LocalStorageUtils from '../utils/localStorageUtils';
import { messages, setNewArrayMessages } from '../Context/messagesMemory';

const useStatus = () => {
  const { updateStatusMessages } = useMessengerContext();

  const { widgetIsFocused, setWidgetIsFocused, webchatIsOpen } =
    useChatContext();

  const [pageIsOpen, setPageIsOpen] = useState(true);

  const { setOnLocalStorage } = LocalStorageUtils();

  /* UseEffect responsável por caso a página esteja sendo exibida, atualizar os status das mensagens que ainda não estão como 'read' */
  useEffect(() => {
    if (widgetIsFocused) {
      const messagesNotRead = messages.filter(
        (message) => message.status === 'delivered'
      );

      if (messagesNotRead.length > 0) {
        const updatedMessages = updateStatusMessages(messages);
        setNewArrayMessages(updatedMessages);
        setOnLocalStorage('session-messages', JSON.stringify(updatedMessages));
      }
    }
  }, [widgetIsFocused]);

  /* UseEffect responsável por verificar se a página está sendo exibida e o widget aberto, setando a variável widgetIsFocused */
  useEffect(() => {
    if (pageIsOpen && webchatIsOpen) {
      setWidgetIsFocused(true);
    } else {
      setWidgetIsFocused(false);
    }
  }, [pageIsOpen, webchatIsOpen]);

  /* UseEffect responsável criar eventListener que verifica troca de página. */
  useEffect(() => {
    const changeBrowserFocus = () => {
      setPageIsOpen(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', changeBrowserFocus);

    return () => {
      document.removeEventListener('visibilitychange', changeBrowserFocus);
    };
  }, []);

  return () => {};
};

export default useStatus;
