import styled from 'styled-components';
import { getTypingSize } from '../../../../utils/getSizes';

export const TypingWrapper = styled.div`
  .tiblock {
    align-items: center;
    display: flex;
    height: 5px;
  }

  .tidot {
    animation: mercuryTypingAnimation 1s infinite ease-in-out;
    border-radius: 50%;
    display: inline-block;
    width: ${({ fontSize }) => getTypingSize(fontSize)};
    height: ${({ fontSize }) => getTypingSize(fontSize)};
    margin-right: 4px;
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
    }
    28% {
      transform: translateY(-5px);
    }
    44% {
      transform: translateY(0px);
    }
  }

  .tidot:nth-child(1) {
    animation-delay: 200ms;
  }
  .tidot:nth-child(2) {
    animation-delay: 300ms;
  }
  .tidot:nth-child(3) {
    animation-delay: 400ms;
  }
`;
