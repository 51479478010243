import React, { useContext } from 'react';

import { SettingsContext } from '../../Context/SettingsContext';

import * as S from './styles';

const WidgetButton = ({ widgetIsOpen, handleWidgetClick }) => {
  const { bot } = useContext(SettingsContext);
  const { botFab: webchatIcon, iconSize } = bot?.channel?.settings;
  return (
    <S.Container
      widgetIsOpen={widgetIsOpen}
      onClick={() => handleWidgetClick()}
      iconSize={iconSize}
    >
      <img
        src={webchatIcon}
        alt=""
        className={widgetIsOpen ? 'webchat_opened' : 'webchat_closed'}
      />
    </S.Container>
  );
};

export default WidgetButton;
