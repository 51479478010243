export let messages = [];

export const setNewArrayMessages = (newArray) => {
  messages = newArray;
};

export const setMessages = (message) => {
  const newArray = messages.filter((message) => message?.type !== 'TYPING');
  setNewArrayMessages(newArray);
  messages.push(message);
};

export const cleanMessages = () => {
  messages.length = 0;
};
