import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  font-size: 10px;
  text-indent: -9999em;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: transparent;

  position: relative;
  animation: ${rotate} 800ms infinite linear;

  &:after {
    background: ${({ variant, background }) =>
      !background && (variant === 'ghost' || variant === 'outline')
        ? '#f3f5f9'
        : '#102693'};
    background: ${({ background }) => background !== '' && background};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    inset: 0;
    transition-duration: 0.2s, 0.2s, 0.2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  }

  &:before {
    width: 50%;
    height: 50%;
    background: ${({ variant }) =>
      variant === 'ghost' || variant === 'outline' ? '#5a5d68' : '#ffffff'};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
`;

const buttonFillCss = css`
  &.fill {
    color: #ffffff;
    border: 0px;

    &.primary {
      background: #102693;
      & ${Loader}:after {
        background: #102693;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #1939b7;
      }
      &.active {
        background: #254edb;
      }
    }

    &.success {
      background: #054504;
      & ${Loader}:after {
        background: #054504;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #0e5507;
      }
      &.active {
        background: #19660a;
      }
    }

    &.danger {
      background: #5b0a1f;
      & ${Loader}:after {
        background: #5b0a1f;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #711021;
      }
      &.active {
        background: #871821;
      }
    }

    &.warning {
      color: #682e01;
      background: #fbeeca;
      & ${Loader}:after {
        background: #fbeeca;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #e8b960;
      }
      &.active {
        background: #fbeeca;
      }
    }

    &:disabled {
      background: #f3f5f9;
      color: #979aa5;
      &:hover {
        background: #f3f5f9;
      }
    }
  }
`;

const buttonGhostCss = css`
  &.ghost {
    border: 0px;

    &.primary {
      background: #f3f5f9;
      color: #102693;
      & ${Loader}:after {
        background: #f3f5f9;
      }

      &.ghost:hover,
      &.ghost:hover ${Loader}:after {
        background: #dadce3;
      }
      &.ghost.active {
        background: #adc8ff;
      }
    }

    &.success {
      color: #054504;
      background: #e3f8cc;
      & ${Loader}:after {
        background: #e3f8cc;
      }

      & .icon svg path {
        fill: #054504;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #c2f19d;
      }
      &.active {
        background: #e3f8cc;
      }
    }

    &.danger {
      color: #5b0a1f;
      background: #fae0d2;
      & ${Loader}:after {
        background: #fae0d2;
      }

      & .icon svg path {
        fill: #5b0a1f;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #f5bba7;
      }
      &.active {
        background: #fae0d2;
      }
    }

    &.warning {
      color: #682e01;
      background: #fbeeca;
      & ${Loader}:after {
        background: #fbeeca;
      }

      &:hover,
      &:hover ${Loader}:after {
        background: #e8b960;
      }
      &.active {
        background: #fbeeca;
      }
    }

    &:disabled {
      background: none;
      color: #979aa5;
      border: solid 1px #979aa5;
      &:hover {
        background: none !important;
      }
    }
  }
`;

const buttonNeutralCss = css`
  &.neutral {
    border: 0px;

    &.primary {
      background: #f3f5f9;
      color: #5a5d68;
      &.neutral:hover {
        background: #dadce3;
      }
      &.neutral.active {
        background: #f3f5f9;
      }
    }

    &.success {
      color: #054504;
      background: #e3f8cc;
      &:hover {
        background: #c2f19d;
      }
      &.active {
        background: #e3f8cc;
      }
    }

    &.danger {
      color: #5b0a1f;
      background: #fae0d2;
      &:hover {
        background: #f5bba7;
      }
      &.active {
        background: #fae0d2;
      }
    }

    &.warning {
      color: #682e01;
      background: #fbeeca;
      &:hover {
        background: #e8b960;
      }
      &.active {
        background: #fbeeca;
      }
    }

    &:disabled {
      background: #f3f5f9;
      color: #979aa5;
      &:hover {
        background: #f3f5f9;
      }
    }
  }
`;

const buttonOutlineCss = css`
  &.outline {
    background: var(transparent);

    &.primary {
      color: #102693;
      border: 1px solid #102693;

      &:hover {
        background: #d6e4ff;
        border: 1px solid;
      }
      &.active {
        background: var(transparent);
        border: 1px solid;
      }
    }

    &.success {
      border: 1px solid #054504;
      background-color: none;
      color: #054504;
      &:hover {
        background-color: #e3f8cc;
      }
      &.active {
        background-color: var(transparent);
      }
    }

    &.danger {
      border: 1px solid #5b0a1f;
      background-color: none;
      color: #5b0a1f;
      &:hover {
        background-color: #fae0d2;
      }
      &.active {
        background-color: var(transparent);
      }
    }

    &.warning {
      border: 1px solid #fbeeca;
      color: #682e01;
      background-color: none;
      &:hover {
        color: #fbeeca;
        background-color: #e8b960;
      }
      &.active {
        background-color: var(transparent);
      }
    }

    &:disabled {
      color: #979aa5;
      background: none;
      border-color: #979aa5;
    }
  }
`;

export const Container = styled.button`
  border-radius: 4px;
  font-family: inherit;
  font-size: 0.875rem;
  letter-spacing: -0.3px;
  line-height: 114%;
  font-weight: 500;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  .btnContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  & span {
    display: grid;
    place-items: center;
  }

  & .icon {
    width: 20px;
    display: flex;
    align-items: center;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #6690ff;
  }

  &.small {
    height: 32px;
    padding: 8px 16px;
  }
  &.medium {
    height: 40px;
    padding: 12px 20px;
  }
  &.large {
    height: 64px;
    padding: 12px 24px;
  }
  &.full {
    width: 100%;
  }

  ${buttonFillCss}
  ${buttonGhostCss}
  ${buttonNeutralCss}
  ${buttonOutlineCss}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  &:disabled {
    cursor: initial;
  }
`;
