import React, {
  createContext,
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';

import {
  AUDIO_RECEIVING_ALERT,
  AUDIO_SENDING_ALERT,
} from '../constants/notificationsSound';

import {
  initScriptRybena,
  openPlayer,
  loadedPlayer,
  translate,
  filterMessagesWithoutTranslate,
  finishedTranslate,
  hasRybena,
  changeStyleMobile,
} from '../utils/rybena';
import { filterMessagesBot } from '../utils/filterMessagesBot';

import { SettingsContext } from './SettingsContext';
import { messages, setNewArrayMessages } from './messagesMemory';

const ChatProvider = ({ children }) => {
  const [currentBotId, setCurrentBotId] = useState('');
  const [socketId, setSocketId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [lastSessionId, setLastSessionId] = useState('');
  const [historicMessages, setHistoricMessages] = useState([]);
  const [hasHistoryToLoad, setHasHistoryToLoad] = useState(true);
  const [hasIdentifierClient, setHasIdentifierClient] = useState(false);
  const [messagesOnlyBot, setMessagesOnlyBot] = useState([]);
  const [, setSessionMessages] = useState([]);
  const [uniqueSessionIds, setUniqueSessionIds] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [imgTitle, setImgTitle] = useState(false);
  const [imgSrc, setImgSrc] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const clientRef = useRef(null);
  const messageListRef = useRef(null);
  const [alreadyIntarectedPage, setAlreadyIntarectedPage] = useState(false);
  const [alreadyHasAudio, setAlreadyHasAudio] = useState(false);
  const [widgetIsFocused, setWidgetIsFocused] = useState(true);
  const [pageIsOpen, setPageIsOpen] = useState(true);
  const [conversationIsStarted, setConversationIsStarted] = useState(false);
  const [webchatIsOpen, setWebchatIsOpen] = useState(false);
  const [webchatIsStarted, setWebchatIsStarted] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(true);
  const [channelsBarIsOpen, setChannelsBarIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [hasTranslate, setHasTranslate] = useState(false);
  const [translateSelectedText, setTranslateSelectedText] = useState('');
  const [resetChat, setResetChat] = useState(false);
  const [changeTranslate, setChangeTranslate] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [handleResetTranslate, setHandleResetTranslate] = useState(false);
  const [interruptMessage, setInterruptMessage] = useState(false);
  const [clientMessage, setClientMessge] = useState(false);
  const [translateMessages, setTranslateMessages] = useState([]);
  const [iframeState, setIframeState] = useState();

  const { bot, customSettings } = useContext(SettingsContext);
  const { webchatType } = customSettings;
  const { channel } = bot;

  const updateScrollToTop = useCallback(() => {
    if (messageListRef?.current) {
      setTimeout(() => {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }, 50);
    }
  }, [messageListRef]);

  useEffect(() => {
    updateScrollToTop();
  }, [historicMessages, messages, updateScrollToTop]);

  useEffect(() => {
    if (!iframeState) return;
    const lastMessage = messages && messages[messages.length - 1];
    if (lastMessage && lastMessage?.type?.toLowerCase() === 'webview') return;
    setIframeState((prevState) => {
      return {
        ...prevState,
        state: 'closed',
      };
    });
  }, [messages]);

  const notificationSounds = useMemo(() => {
    if (channel && channel.isNotificationSound) {
      const receivingAudio = new Audio(AUDIO_RECEIVING_ALERT);
      const sendingAudio = new Audio(AUDIO_SENDING_ALERT);
      receivingAudio.volume = 0.7;
      sendingAudio.volume = 0.7;
      return {
        receivingAudio,
        sendingAudio,
      };
    }
  }, [channel?.isNotificationSound]);

  useEffect(() => {
    if (
      webchatType === 'fullscreen' &&
      window?.RybenaDOM &&
      window?.RybenaDOM?.getInstance()
    ) {
      initScriptRybena();
    }
  }, [window?.RybenaDOM]);

  useEffect(() => {
    if (window?.RybenaApi && window?.RybenaApi?.getInstance()) {
      openPlayer();
    }
  }, [window?.RybenaApi]);

  useEffect(() => {
    const checkPlayerLoaded = () => {
      if (!loadedPlayer()) {
        setTimeout(checkPlayerLoaded, 1000);
      } else if (!isLoaded) {
        setIsLoaded(true);
      }
    };

    checkPlayerLoaded();
  }, []);

  const checkTranslation = () => {
    const translationFinished = finishedTranslate(
      setHasTranslate,
      setInterruptMessage,
      setResetChat,
      setMessagesOnlyBot,
      changeTranslate
    );
    if (loadedPlayer()) {
      if (!translationFinished) {
        setTimeout(checkTranslation, 3000);
      }
    }
  };

  checkTranslation();

  useEffect(() => {
    setMessagesOnlyBot((prevMessages) => {
      if (!prevMessages.isTranslate && prevMessages.from === 'bot') {
        return {
          ...prevMessages,
          isTranslate: true,
        };
      }
      return prevMessages;
    });
  }, [hasTranslate]);

  useEffect(() => {
    if (messagesOnlyBot.isTranslate) {
      const newMessages = messages.map((message) => {
        if (message.id === messagesOnlyBot.id) {
          return messagesOnlyBot;
        }
        return message;
      });
      setNewArrayMessages(newMessages);
      setHasTranslate(false);
    }
  }, [messagesOnlyBot]);

  useEffect(() => {
    setMessagesOnlyBot('');

    if (
      messages.length &&
      isLoaded &&
      !interruptMessage &&
      !resetChat &&
      !clientMessage &&
      !handleResetTranslate
    ) {
      const messagesFromBot = filterMessagesBot(messages);
      if (messagesFromBot.length) {
        const messagesWithoutTranslate =
          filterMessagesWithoutTranslate(messagesFromBot);
        if (messagesWithoutTranslate.length) {
          const messageToTranslate = messagesWithoutTranslate[0];
          setMessagesOnlyBot(messageToTranslate);
          setChangeTranslate(false);
          localStorage.removeItem('TYPE_TRANSLATE');
          translate(messageToTranslate);
        }
      }
    }
  }, [
    messages,
    isLoaded,
    interruptMessage,
    resetChat,
    clientMessage,
    handleResetTranslate,
  ]);

  useEffect(() => {
    if (clientMessage) {
      const newMessages = messages.map((message) => {
        const getMessage = translateMessages.filter(
          (translateMessage) => translateMessage.id === message.id
        );
        if (getMessage.length) {
          return {
            ...message,
            isTranslate: true,
          };
        }
        return message;
      });

      setInterruptMessage(false);
      setClientMessge(false);
      setNewArrayMessages(newMessages);
    }
  }, [translateMessages]);

  const playNotificationSound = (type, isMute) => {
    if (
      !webchatType ||
      (webchatType === 'fullscreen' && !alreadyIntarectedPage)
    )
      return;
    if (channel && channel?.isNotificationSound && !isMute) {
      if (type === 'receiving') {
        notificationSounds?.receivingAudio?.play();
      } else {
        notificationSounds?.sendingAudio?.play();
      }
    }
  };

  useEffect(() => {
    const handleTextSelection = () => {
      const selectedText = window?.getSelection()?.toString();
      if (selectedText) {
        setTranslateSelectedText(selectedText);
        try {
          window?.RybenaApi?.getInstance()?.stop();
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    };

    document.addEventListener('mouseup', handleTextSelection);
    document.addEventListener('touchend', handleTextSelection);

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      document.removeEventListener('touchend', handleTextSelection);
    };
  });

  useEffect(() => {
    if (!hasTranslate && translateSelectedText.length) {
      translate({ message: translateSelectedText, id: translateSelectedText });
      setTranslateSelectedText('');
    }
  }, [translateSelectedText, hasTranslate]);

  useEffect(() => {
    if (handleResetTranslate && hasRybena() && webchatType === 'fullscreen') {
      translate({ message: 'vamos reiniciar seu chat nesse momento' });
      setTimeout(() => {
        setHandleResetTranslate(false);
      }, 5000);
    }
  }, [handleResetTranslate]);

  useEffect(() => {
    if (hasRybena() && webchatType === 'fullscreen') {
      changeStyleMobile();
    }
  }, [hasRybena(), loadedPlayer()]);

  return (
    <ChatContext.Provider
      value={{
        messageListRef,
        clientRef,
        sessionId,
        setSessionId,
        historicMessages,
        setHistoricMessages,
        messagesOnlyBot,
        setMessagesOnlyBot,
        handleResetTranslate,
        setResetChat,
        setHasTranslate,
        setInterruptMessage,
        setHandleResetTranslate,
        setSessionMessages,
        fullScreen,
        setFullScreen,
        imgTitle,
        setImgTitle,
        imgSrc,
        setImgSrc,
        isMute,
        setClientMessge,
        setIsMute,
        changeTranslate,
        setChangeTranslate,
        alreadyHasAudio,
        setAlreadyHasAudio,
        socketId,
        setSocketId,
        widgetIsFocused,
        setWidgetIsFocused,
        pageIsOpen,
        setPageIsOpen,
        playNotificationSound,
        updateScrollToTop,
        lastSessionId,
        setLastSessionId,
        setConversationIsStarted,
        conversationIsStarted,
        alreadyIntarectedPage,
        setAlreadyIntarectedPage,
        webchatIsOpen,
        setWebchatIsOpen,
        setWebchatIsStarted,
        webchatIsStarted,
        setPopupIsOpen,
        popupIsOpen,
        channelsBarIsOpen,
        setChannelsBarIsOpen,
        setTranslateMessages,
        timeoutId,
        setTimeoutId,
        uniqueSessionIds,
        setUniqueSessionIds,
        currentBotId,
        setCurrentBotId,
        hasHistoryToLoad,
        setHasHistoryToLoad,
        hasIdentifierClient,
        setHasIdentifierClient,
        iframeState,
        setIframeState,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
export const ChatContext = createContext();
export const useChatContext = () => useContext(ChatContext);
