import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ContainerHistoricMessages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px;
  border-radius: 8px;
  background-color: #dadce3;
  width: 267px;
  gap: 8px;
`;

export const ContainerNoMoreMessages = styled.div`
  border: 1px dashed #979aa5;
  border-radius: 8px;
  background-color: #fff;
  width: 267px;
`;

export const LineWithDate = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BottonLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0px 16px 0px;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: #dadce3;
`;
