import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: max-content;
`;

export const WrapperButton = styled.span`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  position: absolute;
  display: none;
  z-index: 10;
  background-color: #fff;

  ${({ contentWidth }) =>
    !!contentWidth &&
    css`
      width: ${contentWidth};
    `}

  &.is-open {
    display: block;
  }

  &.left {
    top: 0;
    right: ${({ buttonWidth, contentOffset }) =>
      `${buttonWidth + contentOffset}px`};
  }

  &.right {
    top: 0;
    left: ${({ buttonWidth, contentOffset }) =>
      `${buttonWidth + contentOffset}px`};
  }

  &.bottom {
    right: 0;
    top: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }

  &.top {
    right: 0;
    bottom: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }

  &.top-left {
    left: 0;
    bottom: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }

  ::-webkit-scrollbar {
    padding: 8px !important;
    margin: 8px !important;
    width: 4px !important;
    border-right: 2px solid white !important;
    background-color: #ffffff;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #dadce3;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #dadce3;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 16px;
  white-space: nowrap;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #35373f;
  user-select: none;

  &.active {
    background: #adc8ff;
    color: #254edb;
  }

  &.disabled {
    cursor: not-allowed !important;
  }

  & > .icon {
    width: 18px;

    & > svg {
      width: 100%;
      fill: #35373f;
    }
  }

  &:hover {
    background: #d6e4ff;
    color: #254edb;
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`;
