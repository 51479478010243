import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import SettingsProvider from './Context/SettingsContext';
import { FontProvider } from './styles/fontProvider';

const indexRoot = document.getElementById('root');
const webchatDom = document.getElementById('boteria_webchat');

let root = false;

const initialConfig = {
  webchatType: 'fullscreen',
  variables: {},
  isToResetIfParamsChange: false,
};

const renderWidget = (root, config = initialConfig) => {
  root.render(
    <FontProvider fontIsDisabled={config?.fontIsDisabled}>
      <SettingsProvider>
        <App config={config} />
      </SettingsProvider>
    </FontProvider>
  );
};

if (indexRoot instanceof Element && !(webchatDom instanceof Element)) {
  root = ReactDOM.createRoot(indexRoot);
  if (root) {
    renderWidget(root);
  }
}

/**
 * Responsável por renderizar o Widget do Webchat em uma página HTML.
 * @param {object} botId Id do Bot
 * @param {object} variables Objeto de variáveis. Nela pode conter um objeto "layout".
 * @param {object} isToResetIfParamsChange Define se a sessão deve reiniciar caso parâmetros sejam alterados
 * @param {object} frameRender Elemento id do HTML para renderizar o widget.
 */
export const renderBotWidget = (
  botId,
  variables = {},
  isToResetIfParamsChange = false,
  frameRender = false
) => {
  const element = document.createElement('div');
  element.id = frameRender || 'boteria_webchat';
  document.body.appendChild(element);

  const fontIsDisabled = variables?.layout
    ? variables?.layout['disable-boteria-font']
    : false;

  const config = {
    botId,
    webchatType: 'webchat',
    variables,
    isToResetIfParamsChange,
    fontIsDisabled,
    handleRybena: false,
  };
  root = ReactDOM.createRoot(element);
  return renderWidget(root, config);
};

/**
 * Responsável por renderizar o Widget do Webchat em uma página HTML.
 * @param {object} botId Id do Bot
 * @param {object} variables Objeto de variáveis. Nela pode conter um objeto "layout" e o webchatType.
 * @param {object} isToResetIfParamsChange Define se a sessão deve reiniciar caso parâmetros sejam alterados
 * @param {object} frameRender Elemento id do HTML para renderizar o widget.
 */
export const renderChannelsBar = (
  botId,
  variables = {},
  isToResetIfParamsChange = false,
  frameRender = false
) => {
  const element = document.createElement('div');
  element.id = frameRender || 'boteria_webchat';
  document.body.appendChild(element);
  const webchatType =
    variables?.webchatType === 'fullscreen' ? 'fullscreen' : 'webchat';

  const fontIsDisabled = variables?.layout
    ? variables?.layout['disable-boteria-font']
    : false;

  const config = {
    botId,
    webchatType,
    isChannelsBar: true,
    variables,
    isToResetIfParamsChange,
    fontIsDisabled,
    handleRybena: false,
  };
  root = ReactDOM.createRoot(element);
  return renderWidget(root, config);
};
