import React from 'react';
import { useSettingsContext } from '../../Context/SettingsContext';

import Typography from '../Typography';

import * as S from './styles';

import { hasRybena } from '../../utils/rybena';

const SuggestionList = ({ suggestionList, onSuggestionClick }) => {
  const { fontSize } = useSettingsContext();

  const applyMaxLength = (suggestion) => {
    if (suggestion?.length > 20) {
      suggestion = `${suggestion.substring(0, 20)}...`;
    }
    return suggestion;
  };

  return (
    <S.SuggestionListWrapper hasRybena={hasRybena()}>
      {suggestionList?.map((suggestion, index) => (
        <S.SuggestionButton
          key={String(`key-${index}`)}
          onClick={() =>
            onSuggestionClick({
              message: suggestion?.trim?.(),
              type: 'TEXT',
            })
          }
          fontSize={fontSize}
        >
          <Typography
            variant="body-small"
            color="primary-600-light"
            noWrap
            as="span"
            data-testid={`${suggestion}`}
          >
            {applyMaxLength(suggestion)}
          </Typography>
        </S.SuggestionButton>
      ))}
    </S.SuggestionListWrapper>
  );
};

export default SuggestionList;
