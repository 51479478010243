export const getBotData = async ({ botId, isChannelsBar = false }) => {
  let botData;
  const LAMBDA_URL = process.env.REACT_APP_LAMBDA_URL;

  if (LAMBDA_URL) {
    const url = `${LAMBDA_URL}/${botId}`;

    botData = await fetch(url)
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  if (botData?.channels) {
    botData.channel = botData.channels.find(
      (channel) => channel.channelId.toLowerCase() === 'webchat'
    );

    if (isChannelsBar) {
      botData.channelsBar = botData.channels.find(
        (channel) => channel.channelId.toLowerCase() === 'channelsbar'
      );
    }

    return botData;
  }
  return {};
};
