import React from 'react';
import { useSettingsContext } from '../../../../Context/SettingsContext';

import Typography from '../../../Typography';
import * as S from './styles';

const Document = ({ message }) => {
  const { fontSize, customSettings } = useSettingsContext();
  const { layout } = customSettings;
  const {
    backgroundBotColor,
    textBotColor,
    backgroundUserColor,
    textUserColor,
  } = layout;

  const isUserMessage = message?.from === 'user';
  const textColor = isUserMessage ? textUserColor : textBotColor;
  const backgroundColor = isUserMessage
    ? backgroundUserColor
    : backgroundBotColor;

  return (
    <S.CustomDocument
      href={
        message.document?.fileUrl ||
        message.document?.message ||
        message.document?.title ||
        ''
      }
      target="_blank"
      rel="noopener noreferrer"
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      textColor={textColor}
      isUserMessage={isUserMessage}
      download={message.fileName || message.document?.name}
    >
      <svg viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.5 7C16.5 3.53125 13.4531 0.71875 9.89062 1.04688C6.75 1.375 4.5 4.14062 4.5 7.28125V15.25C4.5 17.4531 6.375 19.2344 8.625 19C10.5469 18.8125 12 17.0312 12 15.0625V7.75C12 7.375 11.625 7 11.25 7H9.75C9.32812 7 9 7.375 9 7.75V15.25C9 15.6719 8.625 16 8.25 16C7.82812 16 7.5 15.6719 7.5 15.25V7C7.5 5.21875 9.09375 3.76562 10.9688 4.04688C12.4688 4.32812 13.5 5.6875 13.5 7.1875V16.75C13.5 20.0312 10.5 22.6094 7.125 21.9062C4.6875 21.3906 3 19.1406 3 16.6094V10.75C3 10.375 2.625 10 2.25 10H0.75C0.328125 10 0 10.375 0 10.75V16.4219C0 20.7344 3.09375 24.5781 7.40625 25C12.3281 25.4688 16.5 21.5781 16.5 16.75V7Z"
          fill={textColor}
        />
      </svg>

      <Typography variant="body-small" noWrap>
        {message.fileName || message.document?.name || message.document?.title}
      </Typography>
    </S.CustomDocument>
  );
};

export default Document;
