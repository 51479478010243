import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import * as S from './styles';

const CarouselActions = ({
  onPrevious,
  onNext,
  previousDisabled,
  nextDisabled,
}) => {
  return (
    <S.CarouselActionsWrapper>
      <button type="button" onClick={onPrevious} disabled={previousDisabled}>
        <FaChevronLeft />
      </button>
      <button type="button" onClick={onNext} disabled={nextDisabled}>
        <FaChevronRight />
      </button>
    </S.CarouselActionsWrapper>
  );
};

export default CarouselActions;
