import React from 'react';
import { useSettingsContext } from '../../../../Context/SettingsContext';

import * as S from './styles';

const TimeOnMessage = ({ time, color }) => {
  const { fontSize, customSettings } = useSettingsContext();
  const fontIsDisabled = customSettings?.layout?.fontIsDisabled || false;

  return (
    <S.Time color={color} fontSize={fontSize} fontIsDisabled={fontIsDisabled}>
      {time}
    </S.Time>
  );
};

export default TimeOnMessage;
