import React, { forwardRef, useMemo, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { useSettingsContext } from '../../Context/SettingsContext';
import { useChatContext } from '../../Context/ChatContext';
import { useMessengerContext } from '../../Context/MessengerContext';
import { messageComponentByType } from './messageComponentByType';
import SuggestionList from '../SuggestionList';
import ButtonsRybena from '../ButtonsRybena';
import { orderHistoricMessages } from '../../utils/messengerUtils';
import ButtonLoadMessages from './components/HistoricMessages/ButtonLoadMessages';
import MessagesHistory from './components/HistoricMessages/MessagesHistory';
import * as S from './styles';
import { messages } from '../../Context/messagesMemory';

const domPurify = DOMPurify.sanitize;

const Messages = forwardRef(
  (
    {
      channel = 'webchat',
      handleClickImage,
      onSuggestionClick,
      hasButtonsRybena,
    },
    ref
  ) => {
    const {
      historicMessages,
      hasHistoryToLoad,
      hasIdentifierClient,
      updateScrollToTop,
      messagesOnlyBot,
      uniqueSessionIds,
    } = useChatContext();
    const { handleCarouselButtonClick, handleLoadPreviousMessages } =
      useMessengerContext();

    const { bot, customSettings } = useSettingsContext();
    const [hasRybena, setHasRybena] = useState(false);
    const { webchatType } = customSettings;
    const hasFooter = bot?.channel?.poweredBy || false;

    const suggestionList = useMemo(() => {
      if (!messages?.length) return [];
      const lastMessage = messages[messages?.length - 1];
      return lastMessage?.suggestionList || [];
    }, [messages]);

    useEffect(() => {
      updateScrollToTop();
    }, []);

    useEffect(() => {
      const hasRybenaApi = document.getElementById('import-rybena-api');
      if (hasRybenaApi) {
        setHasRybena(true);
      }
    }, [document.getElementById('import-rybena-api')]);

    const renderHistoricMessages = () => {
      const historicMessagesOrdered = orderHistoricMessages(historicMessages);
      return (
        <>
          {hasIdentifierClient && (
            <ButtonLoadMessages
              hasHistoryToLoad={hasHistoryToLoad}
              loadMoreMessages={handleLoadPreviousMessages}
            />
          )}
          {historicMessagesOrdered.length > 0 ? (
            <MessagesHistory
              historyMessages={historicMessagesOrdered}
              uniqueSessionIds={uniqueSessionIds}
              channel={channel}
              handleClickImage={handleClickImage}
            />
          ) : null}
          {hasIdentifierClient && (
            <S.BottonLine>
              <S.Line />
            </S.BottonLine>
          )}
        </>
      );
    };

    return (
      <S.MessagesWrapper
        ref={ref}
        id="chat-scrollable"
        className={`channel--${channel}`}
        webchatType={webchatType}
        hasFooter={hasFooter}
      >
        {renderHistoricMessages()}

        {messages.map((message, index) => {
          const Message = messageComponentByType(message?.type);

          const newMessage = message?.message
            ? {
                ...message,
                message: domPurify(message?.message),
              }
            : message;

          return (
            <Message
              key={`key=${index + 1}`}
              message={newMessage}
              onCarouselButtonClick={handleCarouselButtonClick}
              channel={channel}
              handleClickImage={handleClickImage}
              focus={
                message.id === messagesOnlyBot.id &&
                message.type !== 'TYPING' &&
                message.from === 'bot'
              }
            />
          );
        })}

        {hasRybena && hasButtonsRybena && (
          <ButtonsRybena id="change-translate-mobile" />
        )}

        {channel !== 'whatsapp' && !!suggestionList?.length && (
          <SuggestionList
            suggestionList={suggestionList}
            onSuggestionClick={onSuggestionClick}
          />
        )}
      </S.MessagesWrapper>
    );
  }
);

export default Messages;
