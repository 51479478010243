import React from 'react';
import { useSettingsContext } from '../../Context/SettingsContext';
import Typography from '../Typography';

import * as S from './styles';

const Footer = () => {
  const { bot, fontSize, customSettings } = useSettingsContext();
  const { webchatType } = customSettings;

  const returnLink = (link) => {
    const splitLink = link?.split('/');
    if (splitLink && splitLink?.length && splitLink[2] === 'ligo.cloud') {
      return `${link}?utm_source=boteria&utm_medium=bot&utm_campaign=acesso_ligo_bot&utm_id=chatbot`;
    }
    return link;
  };

  const footerPhrase = bot?.channel?.poweredBy || '';
  const footerLink = returnLink(bot?.channel?.poweredByUrl);

  return (
    <S.Container
      fontSize={fontSize}
      webchatType={webchatType}
      hasFooter={!!footerPhrase}
    >
      {footerLink ? (
        <a
          href={footerLink}
          target="_blank"
          rel="noreferrer"
          data-testid="footer"
        >
          <Typography variant="overline-small" data-testid="footer-text">
            {footerPhrase}
          </Typography>
        </a>
      ) : (
        <Typography variant="overline-small" data-testid="footer-text">
          {footerPhrase}
        </Typography>
      )}
    </S.Container>
  );
};

export default Footer;
