import layoutVariables from '../constants/layoutVariables';
import { formatColor } from './formatColor';

const createParamsByType = (params) => {
  const variables = {};
  const layoutParams = {};

  if (params && Object.keys(params).length !== 0) {
    Object.keys(params)?.forEach((prop) => {
      if (layoutVariables.includes(prop)) {
        layoutParams[prop] = params[prop];
      } else {
        variables[prop] = params[prop];
      }
    });
  }

  return { variables, layoutParams };
};

export const treatLayoutParams = (params, botData) => {
  const botSettings = botData.channel?.settings;

  const backgroundBotColor =
    formatColor(params['bot-bg-color']) ||
    botSettings?.secondaryColor ||
    '#f3f5f9';

  const textBotColor =
    formatColor(params['bot-text-color']) ||
    botSettings?.secondaryTextColor ||
    '#5a5d68';

  const backgroundUserColor =
    formatColor(params['user-bg-color']) || botSettings?.mainColor || '#254edb';

  const textUserColor =
    formatColor(params['user-text-color']) ||
    botSettings?.mainTextColor ||
    '#ffffff';

  const botName =
    params['bot-name'] ||
    botData?.channel?.settings?.headerName ||
    botData?.title;
  const backgroundBar = formatColor(params['bar-bg-color']);
  const fontIsDisabled = params['disable-boteria-font'] || false;

  return {
    backgroundBotColor,
    textBotColor,
    backgroundUserColor,
    textUserColor,
    botName,
    backgroundBar,
    fontIsDisabled,
  };
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }

  return createParamsByType(params);
};

export const treatWebchatParams = (params) => {
  let variables = {};
  let layoutParams = {};

  if (params?.layout) {
    layoutParams = params.layout;
    delete params.layout;
  }

  variables = params;

  return { variables, layoutParams };
};

export const getLayoutWidgetParams = (layout) => {
  let params = '';
  if (layout && Object.keys(layout).length !== 0) {
    params = '?';
    Object.keys(layout)?.forEach((prop) => {
      params += `${prop}=${layout[prop]}&`;
    });
  }
  return params;
};
