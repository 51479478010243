import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSettingsContext } from '../../Context/SettingsContext';
import { useChatContext } from '../../Context/ChatContext';

import { getBotData } from '../../services/getBotData';
import { treatWebchatParams, treatLayoutParams } from '../../utils/getParams';

import Channels from './components/Channels';
import Popup from '../../components/Popup';

import * as S from './styles';

const ChannelsBar = ({ config: initialConfig }) => {
  const { bot, setBot, setCustomSettings } = useSettingsContext();
  const { channelsBarIsOpen, webchatIsOpen, popupIsOpen, setPopupIsOpen } =
    useChatContext();

  const config = initialConfig || {};
  const { botId: configBotId, variables: initialVariables } = config;
  const { botId: paramsBotId } = useParams();
  const botId = configBotId || paramsBotId;

  /* UseEffect responsável pegar os dados do bot e configurações do webchat */
  useEffect(() => {
    if (botId) {
      (async () => {
        const botData = await getBotData({ botId, isChannelsBar: true });
        if (botData && botData?.channelsBar && botData?.channelsBar?.isActive) {
          setBot({
            ...botData,
            _id: botId,
          });

          const { variables, layoutParams } =
            treatWebchatParams(initialVariables);
          const layout = treatLayoutParams(layoutParams, botData);

          setCustomSettings({
            ...config,
            webchatType: config?.webchatType || 'webchat',
            isChannelsBar: true,
            variables,
            layout,
          });
        }
      })();
    }
  }, [botId]);

  return (
    <>
      {bot?._id && bot?.channelsBar && (
        <S.Container>
          <Channels />
          {bot?.popupMessage?.isActive && (
            <Popup
              popupIsOpen={popupIsOpen}
              setPopupIsOpen={setPopupIsOpen}
              webchatIsOpen={webchatIsOpen}
              channelsBarIsOpen={channelsBarIsOpen}
            />
          )}
        </S.Container>
      )}
    </>
  );
};

export default ChannelsBar;
