import { useEffect } from 'react';

import { useSettingsContext } from '../Context/SettingsContext';
import { useChatContext } from '../Context/ChatContext';

import LocalStorageUtils from '../utils/localStorageUtils';
import { formatVariables } from '../utils/formatValues';
import { messages, setMessages } from '../Context/messagesMemory';

const useStorage = () => {
  const { customSettings } = useSettingsContext();
  const { botId } = customSettings;

  const { sessionId, setSessionId } = useChatContext();

  const { variables, isToResetIfParamsChange } = customSettings;

  const { setOnLocalStorage, getOnLocalStorage, removeOnLocalStorage } =
    LocalStorageUtils();

  /* UseEffect responsável por trazer e remover dados de mensagens e do storage ao ser iniciado. */
  useEffect(() => {
    if (botId) {
      const stringVariables = JSON.stringify(formatVariables(variables));
      const currentParams = getOnLocalStorage('session-params', botId);

      if (isToResetIfParamsChange && stringVariables !== currentParams) {
        removeOnLocalStorage(
          ['session-id', 'session-messages', 'frame-render'],
          botId
        );
      }

      if (!sessionId) {
        setSessionId(getOnLocalStorage('session-id'));
      }

      if (!messages) {
        const messagesOnStorage = getOnLocalStorage('session-messages');
        setMessages(messagesOnStorage ? JSON.parse(messagesOnStorage) : []);
      }

      if (variables) {
        setOnLocalStorage('session-params', stringVariables, botId);
      }
    }
  }, [customSettings]);

  return () => {};
};

export default useStorage;
