import styled from 'styled-components';

export const ContainerCheckbox = styled.label`
  position: relative;
  padding-left: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  font-size: 0.875rem;
  color: #5a5d68;
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & [type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & .checkmark {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid #979aa5;
  }

  &:hover input ~ .checkmark {
    transition: all 0.2s ease;
    background-color: #f3f5f9;
  }

  & input:disabled ~ .checkmark {
    background-color: #dadce3;
  }

  & [type='checkbox']:checked ~ .checkmark {
    /* border-radius: 4px; */
    transition: all 0.2s ease;
    &.primary {
      background: #3366ff;
    }
    &.success {
      background: #38b878;
    }
    &.danger {
      background: #f9675b;
    }
  }

  & .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    border-radius: 3px;
  }

  & [type='checkbox']:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 3px 0;
    transform: rotate(38deg);
  }
`;
