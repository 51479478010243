import styled from 'styled-components';
import { getMessageFontSize } from '../../utils/getSizes';

export const Container = styled.div`
  padding: 16px;
  height: 100%;
  p {
    font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
  }

  .close_settings {
    cursor: pointer;
    span {
      font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
    }
  }

  & button {
    background-color: white;
  }

  .checkbox_options {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label:last-child {
      font-size: 22px;
      :font-variant-position {
        font-size: 32px;
      }
    }

    .checkbox_options_row {
      display: flex;
      align-items: center;
      height: 20px;
    }

    .normal_size {
      font-size: 14px !important;
    }

    .large_size {
      font-size: 18px !important;
    }

    .extra_large_size {
      font-size: 22px !important;
      margin: 0 0 1px -1px;
    }
  }
`;
