import React from 'react';
import DOMPurify from 'dompurify';
import { messageComponentByType } from '../../messageComponentByType';
import Typography from '../../../Typography';
import * as S from './styles';

const domPurify = DOMPurify.sanitize;

const MessagesHistory = ({
  historyMessages,
  uniqueSessionIds,
  handleClickImage,
  channel,
}) => {
  return (
    <>
      {historyMessages.reduce((acc, message, index) => {
        if (
          message.sessionId &&
          !uniqueSessionIds.includes(message.sessionId)
        ) {
          uniqueSessionIds.push(message.sessionId);
        }

        const Message = messageComponentByType(message?.type);
        const newMessage = message?.message
          ? {
              ...message,
              message: domPurify(message?.message),
            }
          : message;

        const messageDate = new Date(message.when).toLocaleDateString('pt-BR');

        const currentSessionId = message.sessionId;
        const lastSessionId =
          index > 0 ? historyMessages[index - 1].sessionId : null;

        if (currentSessionId !== lastSessionId) {
          acc.push(
            <S.LineWithDate key={`date-${messageDate}-${index + 1}`}>
              <S.Line />
              <Typography
                variant="body-small"
                marginLeft="10px"
                marginRight="10px"
              >
                {messageDate}
              </Typography>
              <S.Line />
            </S.LineWithDate>
          );
        }

        acc.push(
          <Message
            key={`message-${index + 1}`}
            message={newMessage}
            channel={channel}
            handleClickImage={handleClickImage}
          />
        );

        return acc;
      }, [])}
    </>
  );
};

export default MessagesHistory;
