import styled from 'styled-components';
import {
  getMessageFontSize,
  getFooterFontSize,
} from '../../../../utils/getSizes';

import Menu, { MenuItem } from '../../../Menu';

export const CustomMenu = styled(Menu)`
  .menu-icon-button {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    width: 18px;
    height: 29px;
  }

  .menu-dropdown {
    margin-bottom: 10px;
    margin-left: -14px;
    max-height: 300px;
    width: 314px;
    overflow-y: auto;
    margin-right: 6px;
  }
`;

export const MenuTitleWrapper = styled.div`
  padding: 8px 16px;
  span {
    font-size: ${({ fontSize }) => getFooterFontSize(fontSize)};
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
`;
