import React from 'react';
import { useSettingsContext } from '../../Context/SettingsContext';
import { useChatContext } from '../../Context/ChatContext';

import { HeaderTitle } from './components';

import * as S from './styles';

const Header = ({
  settingsIsOpen,
  setSettingsIsOpen,
  setWebchatIsOpen,
  handleReset,
}) => {
  const { bot, customSettings, fontSize } = useSettingsContext();
  const { isMute, setIsMute, iframeState } = useChatContext();
  const { webchatType, layout } = customSettings;
  const { backgroundUserColor, textUserColor, botName } = layout;
  const hasSound = !!bot?.channel?.isNotificationSound;

  const handleClickSetting = () => {
    if (iframeState?.state === 'opened') return;
    setSettingsIsOpen(!settingsIsOpen);
  };

  return (
    <S.Container
      fontSize={fontSize}
      backgroundColor={backgroundUserColor}
      textColor={textUserColor}
      webchatType={webchatType}
      hasSound={hasSound}
      isDisabledSettings={iframeState?.state === 'opened'}
    >
      <HeaderTitle
        icon={bot?.channel?.settings?.botFab}
        title={botName}
        isFullscreen={webchatType === 'fullscreen'}
        color={textUserColor}
        hasSound={hasSound}
      />
      <div className="webchat_header_actions">
        {bot?.channel?.isNotificationSound && (
          <div onClick={() => setIsMute(!isMute)}>
            {!isMute ? (
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0938 6.10938C15.7031 5.79688 15.1172 5.875 14.7656 6.26562C14.4531 6.65625 14.4922 7.24219 14.9219 7.59375C15.3516 7.94531 15.625 8.45312 15.625 9C15.625 9.58594 15.3516 10.0938 14.9219 10.4453C14.4922 10.7969 14.4531 11.3828 14.7656 11.7734C14.9609 12.0078 15.2344 12.125 15.5078 12.125C15.7031 12.125 15.9375 12.0469 16.0938 11.9297C16.9922 11.1875 17.5 10.1328 17.5 9C17.5 7.90625 16.9922 6.85156 16.0938 6.10938ZM18.4766 3.21875C18.0469 2.90625 17.4609 2.98438 17.1484 3.375C16.7969 3.76562 16.875 4.35156 17.2656 4.70312C18.5938 5.75781 19.375 7.35938 19.375 9C19.375 10.6797 18.5938 12.2812 17.2656 13.3359C16.875 13.6875 16.7969 14.2734 17.1484 14.6641C17.3438 14.8984 17.5781 15.0156 17.8516 15.0156C18.0859 15.0156 18.2812 14.9375 18.4766 14.8203C20.2344 13.375 21.25 11.2656 21.25 9C21.25 6.77344 20.2344 4.66406 18.4766 3.21875ZM11.7578 0.367188C11.2891 0.171875 10.7812 0.25 10.3906 0.601562L5.11719 5.25H1.875C0.820312 5.25 0 6.10938 0 7.125V10.8359C0 11.8516 0.820312 12.6719 1.875 12.6719H5.11719L10.3906 17.3594C10.625 17.6719 10.9375 17.75 11.25 17.75C11.4062 17.75 11.5625 17.75 11.7578 17.6719C12.1875 17.4375 12.5 17.0078 12.5 16.5V1.5C12.5 1.03125 12.1875 0.5625 11.7578 0.367188Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="26"
                height="21"
                viewBox="0 0 26 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.1094 18.3594L21.6719 15.625C23.2734 14.1797 24.25 12.1484 24.25 10C24.25 7.77344 23.2344 5.66406 21.4766 4.25781C21.0469 3.94531 20.4609 3.98438 20.1484 4.41406C19.7969 4.80469 19.875 5.39062 20.2656 5.74219C21.5938 6.75781 22.375 8.35938 22.375 10C22.375 11.6797 21.5938 13.2812 20.2656 14.3359C20.2266 14.375 20.2266 14.4141 20.1875 14.4531L18.4297 13.125C18.4688 13.125 18.4688 13.125 18.5078 13.125C18.7031 13.125 18.9375 13.0469 19.0938 12.9297C19.9922 12.1875 20.5 11.1328 20.5 10C20.5 8.90625 19.9922 7.85156 19.0938 7.10938C18.7031 6.79688 18.1172 6.875 17.7656 7.26562C17.4531 7.65625 17.4922 8.24219 17.9219 8.59375C18.3516 8.94531 18.625 9.45312 18.625 10C18.625 10.5859 18.3516 11.0938 17.9219 11.4453C17.6094 11.7188 17.4922 12.1094 17.6094 12.4609L15.5 10.7812V2.5C15.5 2.03125 15.1875 1.5625 14.7578 1.36719C14.2891 1.17188 13.7812 1.25 13.3906 1.60156L8.85938 5.625L1.98438 0.234375C1.82812 0.078125 1.63281 0 1.4375 0C1.125 0 0.851562 0.15625 0.695312 0.390625C0.34375 0.78125 0.421875 1.36719 0.851562 1.67969L23.9375 19.7656C24.3281 20.1172 24.9141 20.0391 25.2266 19.6094C25.6172 19.2578 25.5391 18.6719 25.1094 18.3594ZM3 8.125V11.875C3 12.9297 3.82031 13.75 4.875 13.75H8.11719L13.3906 18.3984C13.625 18.6719 13.9375 18.75 14.25 18.75C14.4062 18.75 14.5625 18.75 14.7578 18.6719C15.1875 18.4375 15.5 18.0078 15.5 17.5V15.5469L3.9375 6.52344C3.39062 6.83594 3 7.42188 3 8.125Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        )}
        {!bot?.channel?.disableRestart && (
          <div onClick={handleReset}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5391 1.5625L15.6641 3.47656C14.1406 2.10938 12.1484 1.25 10 1.25C6.28906 1.25 3.04688 3.63281 1.91406 7.14844C1.71875 7.8125 2.07031 8.51562 2.73438 8.71094C3.39844 8.90625 4.10156 8.55469 4.29688 7.89062C5.07812 5.42969 7.38281 3.75 10 3.75C11.4453 3.75 12.8516 4.33594 13.9062 5.23438L12.1484 6.95312C11.5234 7.61719 11.9531 8.75 12.8906 8.75H18.6328C19.0234 8.75 19.375 8.4375 19.375 8.00781V2.34375C19.375 1.36719 18.2031 0.898438 17.5391 1.5625ZM17.2266 11.3281C16.5625 11.1328 15.8594 11.4844 15.6641 12.1484C14.8828 14.6094 12.5781 16.25 10 16.25C8.51562 16.25 7.14844 15.7031 6.05469 14.8047L7.8125 13.0469C8.4375 12.4219 8.00781 11.2891 7.07031 11.25H1.32812C0.9375 11.2891 0.625 11.6016 0.625 12.0312V17.6953C0.625 18.6719 1.75781 19.1406 2.42188 18.4766L4.29688 16.5625C5.82031 17.9297 7.8125 18.75 9.96094 18.75C13.6328 18.75 16.875 16.4062 18.0078 12.8906C18.2422 12.2266 17.8906 11.5234 17.2266 11.3281Z"
                fill="white"
              />
            </svg>
          </div>
        )}
        <div onClick={handleClickSetting} className="webchat_header_settings">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="settings-button"
          >
            <path
              d="M19.3359 6.52344C19.4922 6.875 19.375 7.22656 19.1016 7.5L17.4219 9.02344C17.4609 9.33594 17.5 9.6875 17.5 10C17.5 10.3516 17.4609 10.7031 17.4219 11.0156L19.1016 12.5391C19.375 12.8125 19.4922 13.1641 19.3359 13.5156C19.1797 13.9844 18.9844 14.4141 18.75 14.8438L18.5547 15.1562C18.2812 15.5859 18.0078 16.0156 17.6953 16.4062C17.4609 16.6797 17.0703 16.7578 16.7188 16.6406L14.5703 15.9766C14.0234 16.3672 13.4375 16.6797 12.8516 16.9531L12.3438 19.1797C12.2656 19.5312 11.9922 19.8047 11.6406 19.8828C11.0938 19.9609 10.5469 20 9.96094 20C9.41406 20 8.86719 19.9609 8.32031 19.8828C7.96875 19.8047 7.69531 19.5312 7.61719 19.1797L7.10938 16.9531C6.52344 16.6797 5.9375 16.3672 5.39062 15.9766L3.24219 16.6406C2.89062 16.7578 2.5 16.6797 2.26562 16.4062C1.95312 16.0156 1.67969 15.5859 1.40625 15.1562L1.21094 14.8438C0.976562 14.4141 0.78125 13.9844 0.625 13.5156C0.46875 13.1641 0.585938 12.8125 0.859375 12.5391L2.53906 11.0156C2.5 10.7031 2.5 10.3516 2.5 10C2.5 9.6875 2.5 9.33594 2.53906 9.02344L0.859375 7.5C0.585938 7.22656 0.46875 6.875 0.625 6.52344C0.78125 6.05469 0.976562 5.625 1.21094 5.19531L1.40625 4.88281C1.67969 4.45312 1.95312 4.02344 2.26562 3.63281C2.5 3.35938 2.89062 3.28125 3.24219 3.39844L5.39062 4.0625C5.9375 3.67188 6.52344 3.32031 7.10938 3.08594L7.61719 0.859375C7.69531 0.507812 7.96875 0.234375 8.32031 0.15625C8.86719 0.078125 9.41406 0 10 0C10.5469 0 11.0938 0.078125 11.6406 0.15625C11.9922 0.234375 12.2656 0.507812 12.3438 0.859375L12.8516 3.08594C13.4375 3.32031 14.0234 3.67188 14.5703 4.0625L16.7188 3.39844C17.0703 3.28125 17.4609 3.35938 17.6953 3.63281C18.0078 4.02344 18.2812 4.45312 18.5547 4.88281L18.75 5.19531C18.9844 5.625 19.1797 6.05469 19.3359 6.52344ZM10 13.125C11.7188 13.125 13.125 11.7578 13.125 10C13.125 8.28125 11.7188 6.875 10 6.875C8.24219 6.875 6.875 8.28125 6.875 10C6.875 11.7578 8.24219 13.125 10 13.125Z"
              fill="white"
            />
          </svg>
        </div>
        {webchatType !== 'fullscreen' && (
          <div onClick={() => setWebchatIsOpen(false)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.0059 2.72852C15.5371 2.23047 15.5703 1.40039 15.0391 0.869141C14.541 0.337891 13.7109 0.304688 13.1797 0.835938L8 5.7832L2.78711 0.835938C2.25586 0.304688 1.42578 0.337891 0.927734 0.869141C0.396484 1.40039 0.429688 2.23047 0.960938 2.72852L7.07031 8.57227C7.33594 8.83789 7.66797 8.9375 8 8.9375C8.29883 8.9375 8.63086 8.83789 8.89648 8.57227L15.0059 2.72852Z" />
            </svg>
          </div>
        )}
      </div>
    </S.Container>
  );
};

export default Header;
