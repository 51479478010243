import React from 'react';
import Typography from '../../../Typography';
import * as S from './styles';

const ButtonLoadMessages = ({ hasHistoryToLoad, loadMoreMessages }) => {
  const iconLoad = () => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0625 5.5L11.5312 4.96875C9.5625 3.03125 6.40625 3.03125 4.4375 4.96875C2.5 6.9375 2.5 10.0938 4.4375 12.0625C6.40625 14 9.5625 14 11.5312 12.0625C11.9062 11.6562 12.5312 11.6562 12.9375 12.0625C13.3125 12.4375 13.3125 13.0625 12.9375 13.4688C10.1875 16.1875 5.78125 16.1875 3.03125 13.4688C0.3125 10.7188 0.3125 6.3125 3.03125 3.5625C5.78125 0.84375 10.1875 0.84375 12.9375 3.5625L13.5 4.125V2.5C13.5 1.96875 13.9375 1.5 14.5 1.5C15.0312 1.5 15.5 1.96875 15.5 2.5V6.5C15.5 7.0625 15.0312 7.5 14.5 7.5H10.5C9.9375 7.5 9.5 7.0625 9.5 6.5C9.5 5.96875 9.9375 5.5 10.5 5.5H12.0625Z"
          fill="#5A5D68"
        />
      </svg>
    );
  };
  return (
    <S.ContainerButton>
      {hasHistoryToLoad ? (
        <S.ContainerHistoricMessages onClick={() => loadMoreMessages()}>
          {iconLoad()}
          <Typography variant="body-small">
            Carregar conversa anterior
          </Typography>
        </S.ContainerHistoricMessages>
      ) : (
        <S.ContainerNoMoreMessages>
          <Typography variant="body-small">
            Todas as conversas foram carregadas
          </Typography>
        </S.ContainerNoMoreMessages>
      )}
    </S.ContainerButton>
  );
};

export default ButtonLoadMessages;
