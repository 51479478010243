export default {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  COLLECT: 'Collect',
  TYPING: 'TYPING',
  MESSAGE: 'MESSAGE',
  CAROUSEL: 'CAROUSEL',
  WEBVIEW: 'WEBVIEW',
};
