import React from 'react';
import { useSettingsContext } from '../../../../Context/SettingsContext';

import Typography from '../../../Typography';

import * as S from './styles';

const QuickAccess = ({ quickAccessList, onQuickAccessClick }) => {
  const { fontSize } = useSettingsContext();

  return (
    <S.CustomMenu
      button={
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="menu-icon-button"
        >
          <path
            d="M0.25 1.75C0.25 1.08594 0.796875 0.5 1.5 0.5H16.5C17.1641 0.5 17.75 1.08594 17.75 1.75C17.75 2.45312 17.1641 3 16.5 3H1.5C0.796875 3 0.25 2.45312 0.25 1.75ZM0.25 8C0.25 7.33594 0.796875 6.75 1.5 6.75H16.5C17.1641 6.75 17.75 7.33594 17.75 8C17.75 8.70312 17.1641 9.25 16.5 9.25H1.5C0.796875 9.25 0.25 8.70312 0.25 8ZM16.5 15.5H1.5C0.796875 15.5 0.25 14.9531 0.25 14.25C0.25 13.5859 0.796875 13 1.5 13H16.5C17.1641 13 17.75 13.5859 17.75 14.25C17.75 14.9531 17.1641 15.5 16.5 15.5Z"
            fill="#979AA5"
          />
        </svg>
      }
      position="top-left"
      contentOffset={14}
    >
      <S.MenuTitleWrapper fontSize={fontSize}>
        <Typography variant="overline-small">acesso rápido</Typography>
      </S.MenuTitleWrapper>
      {quickAccessList?.map((item) => (
        <S.CustomMenuItem
          fontSize={fontSize}
          key={item._id}
          onClick={() =>
            onQuickAccessClick({ itemId: item._id, title: item.title })
          }
          title={item.title}
          data-testid={item.title}
        >
          {item.title}
        </S.CustomMenuItem>
      ))}
    </S.CustomMenu>
  );
};

export default QuickAccess;
