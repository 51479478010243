import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  ${createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  `}

  background-color: #f3f5f9;
`;
