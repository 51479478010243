import styled from 'styled-components';

export const Container = styled.label`
  cursor: pointer;
  color: #254edb;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  input {
    display: none;
    width: 100%;
  }
`;
