import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  ${createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  `}

  min-height: 100vh;
  position: fixed;
  z-index: 1;
`;
