import React, { useState } from 'react';

import { useSettingsContext } from '../../../../Context/SettingsContext';
import Typography from '../../../Typography';
import CarouselActions from './CarouselActions';
import CarouselCardButton from './CarouselCardButton';

import * as S from './styles';

const SLIDE_WIDTH = 160;

export default ({ message, onCarouselButtonClick, handleClickImage }) => {
  const [slidePosition, setSlidePosition] = useState(0);
  const { fontSize } = useSettingsContext();

  const previousDisabled = slidePosition === 0;
  const nextDisabled =
    Math.abs(slidePosition) === message?.carousel?.cards?.length - 1;

  const handleNextSlide = () => {
    if (nextDisabled) return;
    setSlidePosition((prevState) => prevState - 1);
  };

  const handlePreviousSlide = () => {
    if (previousDisabled) return;
    setSlidePosition((prevState) => prevState + 1);
  };

  const handleButtonClick = ({ clickedButton, clickedCard }) => {
    onCarouselButtonClick?.({ clickedButton, clickedCard });
  };

  const checkButtonIsEmpty = (button) => {
    return button?.label === '';
  };

  const checkHasInfo = (card) => {
    return card?.title !== '' || card?.description !== '';
  };

  return (
    <>
      <S.CarouselWrapper>
        <S.CarouselSlider>
          <S.CarouselSliderContent
            style={{ left: slidePosition * SLIDE_WIDTH }}
          >
            {message?.carousel?.cards?.map((card) => (
              <div className="slide" key={card._id}>
                <S.CarouselCardInfo
                  hasInfo={checkHasInfo(card)}
                  fontSize={fontSize}
                >
                  {card.imageUrl ? (
                    <S.CustomImage
                      src={card.imageUrl}
                      alt="card"
                      onClick={() => handleClickImage(card.imageUrl)}
                    />
                  ) : (
                    <span />
                  )}
                  <div className="carousel-padding">
                    <Typography
                      variant="caption-small-tight"
                      noWrap
                      title={card.title}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body-small"
                      noWrap
                      as="span"
                      title={card.description}
                    >
                      {card.description}
                    </Typography>
                  </div>
                </S.CarouselCardInfo>
                {card?.buttons?.map(
                  (button) =>
                    !checkButtonIsEmpty(button) && (
                      <CarouselCardButton
                        key={button?._id}
                        onClick={() =>
                          handleButtonClick({
                            clickedCard: card,
                            clickedButton: button,
                          })
                        }
                      >
                        {button?.label}
                      </CarouselCardButton>
                    )
                )}
              </div>
            ))}
          </S.CarouselSliderContent>
        </S.CarouselSlider>
        {message?.carousel?.cards?.length > 1 && (
          <CarouselActions
            onPrevious={handlePreviousSlide}
            onNext={handleNextSlide}
            previousDisabled={previousDisabled}
            nextDisabled={nextDisabled}
          />
        )}
      </S.CarouselWrapper>
    </>
  );
};
