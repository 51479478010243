import React from 'react';

import * as S from './styles';

const MessageImage = ({ message, handleClickImage }) => {
  return (
    <>
      <S.CustomImage
        onClick={() => handleClickImage(message?.image, message?.title)}
        src={message?.image}
        alt={
          message?.isOriginalName ||
          message?.fileDescription ||
          message?.fileName ||
          message?.title
        }
      />
    </>
  );
};

export default MessageImage;
