import styled from 'styled-components';
import { getTimeOnMessageFontSize } from '../../../../utils/getSizes';

export const Time = styled.p`
  margin: 0;
  height: 12px;
  font-family: ${({ fontIsDisabled }) => !fontIsDisabled && 'Archivo'};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => getTimeOnMessageFontSize(fontSize)};
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.02em;
  color: ${({ color }) => color};
  filter: opacity(50%);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 4px;
`;
