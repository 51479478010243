import React, { useState, useEffect, useContext } from 'react';

import MessageText from '../Text';
import Typing from '../Typing';
import Typography from '../../../Typography';
import CloseIcon from '../../../../assets/icons/icon_close_header.svg';
import * as S from './styles';
import { executeRequest } from '../../../../services/api';
import { messages } from '../../../../Context/messagesMemory';
import { ChatContext } from '../../../../Context/ChatContext';
import { SettingsContext } from '../../../../Context/SettingsContext';

const WebView = ({ message }) => {
  const { sessionId, iframeState, setIframeState, webchatIsOpen } =
    useContext(ChatContext);
  const { bot, customSettings } = useContext(SettingsContext);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const backgroundColor = customSettings?.layout?.backgroundUserColor;
  const webchatType = customSettings?.webchatType;
  const hasCloseButton = message?.webView?.hasCloseButton;
  const url = message?.webView?.url;
  const { _id: botId } = bot;
  const closeButtonMessage =
    message?.webView?.closeButtonMessage || 'Fechar página';

  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const setIframePosition = (chatContainer) => {
    if (!chatContainer) return;
    const { top, left, width, height } = chatContainer.getBoundingClientRect();
    setContainerPosition({ top, left, width, height });
  };

  useEffect(() => {
    if (webchatType !== 'fullscreen') return;
    const chatContainer = document.getElementById('chat-scrollable');
    setIframePosition(chatContainer);
    const handleResize = () => {
      setIframePosition(chatContainer);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (webchatType !== 'webchat') return;
    const timer = setTimeout(() => {
      const chatContainer = document.getElementById('chat-scrollable');
      setIframePosition(chatContainer);
    }, 1000);
    return () => clearTimeout(timer);
  }, [webchatIsOpen]);

  useEffect(() => {
    const lastMessage = messages && messages[messages.length - 1];
    if (
      lastMessage?.id === message.id &&
      iframeState?.messageId !== message.id &&
      iframeState?.state !== 'opened'
    ) {
      setIframeState({
        messageId: message.id,
        state: 'opened',
      });
    }
  }, [message]);

  useEffect(() => {
    const handleIframeMessage = (event) => {
      if (event.data === 'closeIframe') {
        setIframeState({
          state: 'closed',
          messageId: message.id,
        });
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [message, setIframeState]);

  const sendCloseWebviewRequest = async () => {
    const method = 'POST';
    const actionUrl = `${process.env.REACT_APP_API_URL}/action`;
    const payload = {
      sessionId,
      botId,
      action: {
        type: 'CONTINUE-FLOW',
        data: {
          clickedButton: { destination: { type: 'nextItem' } },
          responsible: 'client',
          url,
        },
      },
    };
    await executeRequest({ method, url: actionUrl, payload });
  };

  const handleCloseWebView = async () => {
    setIframeState({
      state: 'closed',
      messageId: message.id,
    });
    await sendCloseWebviewRequest();
  };

  const getFormattedUrl = () => {
    if (url?.startsWith('http://') || url?.startsWith('https://')) return url;
    return `https://${url}`;
  };

  const renderIframe = () => {
    return (
      <>
        <S.IFrameContainer
          topPosition={containerPosition.top}
          leftPosition={containerPosition.left}
          width={containerPosition.width}
          height={containerPosition.height}
          backgroundColor={backgroundColor}
        >
          {hasCloseButton && showCloseButton && (
            <button
              type="button"
              onClick={handleCloseWebView}
              className="iframe-close-button"
              key={`close-button-${message.id}`}
            >
              <Typography
                variant="body-small"
                noWrap
                title="Fechar página"
                data-testid="title"
              >
                {closeButtonMessage}
              </Typography>
              <img src={CloseIcon} alt="close iframe" />
            </button>
          )}
          <iframe
            id="webview-iframe"
            src={getFormattedUrl()}
            title={url}
            className="iframe"
            onLoad={() => setShowCloseButton(true)}
          />
        </S.IFrameContainer>
        <Typing />
      </>
    );
  };

  const renderMessage = () => {
    const viewedMessage = `Webview já visualizado. \n${url}`;
    const textMessage = {
      message: {
        ...message,
        message: viewedMessage,
      },
    };
    return MessageText(textMessage);
  };

  const renderComponent = () => {
    const iframeMessageId = iframeState?.messageId;
    if (
      iframeMessageId &&
      (iframeMessageId !== message.id || iframeState?.state === 'closed')
    ) {
      return renderMessage();
    }
    return renderIframe();
  };

  return renderComponent();
};

export default WebView;
