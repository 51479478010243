export const treatMessage = (message) => {
  if (message === '**********') return '✱✱✱✱✱';
  const linkRegex =
    /((http:\/\/|https:\/\/|ftp:\/\/)|(www.))+([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi;
  const strongRegex = /(?<!\*)\*{1,2}(.*?[^*])\*{1,2}(?!\*)/g;
  const breaklineRegex = /\n/g;

  let messageTreated = message?.replace(linkRegex, (str) => {
    if (!str.match(/http/)) {
      return `<a target="_blank" href="http://${str}">${str}</a>`;
    }
    return `<a target="_blank" href="${str}">${str}</a>`;
  });

  messageTreated = messageTreated?.replace(strongRegex, (str) => {
    return `<strong>${str.substr(1, str.length - 2)}</strong>`;
  });

  messageTreated = messageTreated?.replace(breaklineRegex, '<br/>');

  return messageTreated;
};
