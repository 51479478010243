import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 24px;
  right: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: none;
  z-index: 999;
  transition: box-shadow 0.4s ease-in-out;

  -webkit-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.02);

  :hover {
    -webkit-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
  }

  img {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
  }

  .webchat_opened {
    animation: beat1 0.3s;
  }

  .webchat_closed {
    animation: beat2 0.3s;
  }

  @keyframes beat1 {
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes beat2 {
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }
`;
