import React from 'react';
import { useSettingsContext } from '../../../../Context/SettingsContext';

import * as S from './styles';

const Typping = () => {
  const fontSize = useSettingsContext();

  return (
    <S.TypingWrapper fontSize={fontSize}>
      <div className="tiblock">
        <div className="tidot" />
        <div className="tidot" />
        <div className="tidot" />
      </div>
    </S.TypingWrapper>
  );
};

export default Typping;
