import styled from 'styled-components';
import { getMessageFontSize } from '../../../../../utils/getSizes';

export const CarouselCardButton = styled.button`
  background-color: #f3f5f9;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: #102693;
  padding: 0 16px;
  border: none;
  font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
  text-align: left;
  margin-top: 4px;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;
