import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSettingsContext } from '../../Context/SettingsContext';
import { useChatContext } from '../../Context/ChatContext';
import useStatus from '../../Hooks/useStatus';
import useStorage from '../../Hooks/useStorage';

import { getBotData } from '../../services/getBotData';

import WidgetChat from '../../components/WidgetChat';
import WidgetButton from '../../components/WidgetButton';
import Popup from '../../components/Popup';

import { treatWebchatParams, treatLayoutParams } from '../../utils/getParams';

import * as S from './styles';

const Webchat = ({ config: initialConfig }) => {
  const { fontSize, bot, setBot, setCustomSettings } = useSettingsContext();
  const {
    updateScrollToTop,
    webchatIsOpen,
    setWebchatIsOpen,
    webchatIsStarted,
    setWebchatIsStarted,
    popupIsOpen,
    setPopupIsOpen,
  } = useChatContext();

  const config = initialConfig || {};
  const { botId: configBotId, variables: initialVariables } = config;
  const { botId: paramsBotId } = useParams();
  const botId = configBotId || paramsBotId;

  useStatus();
  useStorage();

  /* UseEffect responsável pegar os dados do bot e configurações do webchat */
  useEffect(() => {
    if (botId) {
      (async () => {
        const botData = await getBotData({ botId });
        if (botData && botData?.channel) {
          setBot({
            ...botData,
            _id: botId,
          });

          const { variables, layoutParams } =
            treatWebchatParams(initialVariables);
          const layout = treatLayoutParams(layoutParams, botData);

          setCustomSettings({
            ...config,
            webchatType: 'webchat',
            variables,
            layout,
          });
        }
      })();
    }
  }, [botId]);

  const handleWidgetClick = () => {
    setWebchatIsOpen(!webchatIsOpen);
    updateScrollToTop();
    setWebchatIsStarted(true);
    setPopupIsOpen(false);
  };

  return (
    <>
      {bot?._id && (
        <S.Container fontSize={fontSize}>
          <WidgetChat
            webchatIsOpen={webchatIsOpen}
            webchatIsStarted={webchatIsStarted}
            setWebchatIsOpen={setWebchatIsOpen}
          />
          <WidgetButton
            widgetIsOpen={webchatIsOpen}
            handleWidgetClick={handleWidgetClick}
          />
          {bot?.popupMessage?.isActive && (
            <Popup
              popupIsOpen={popupIsOpen}
              setPopupIsOpen={setPopupIsOpen}
              webchatIsOpen={webchatIsOpen}
              handleWidgetClick={handleWidgetClick}
            />
          )}
        </S.Container>
      )}
    </>
  );
};

export default Webchat;
