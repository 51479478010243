import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const WebchatFullscreen = lazy(() => import('../pages/WebchatFullscreen'));
const Webchat = lazy(() => import('../pages/Webchat'));
const ChannelsBar = lazy(() => import('../pages/ChannelsBar'));
const NotFound = lazy(() => import('../pages/NotFound'));

const Routes = () => {
  const router = createBrowserRouter([
    {
      path: '/:botId',
      element: <WebchatFullscreen />,
    },
    {
      path: '/webchat/:botId',
      element: <Webchat />,
    },
    {
      path: '/channelsbar/:botId',
      element: <ChannelsBar />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routes;
