import styled from 'styled-components';
import { getMessageFontSize } from '../../../../utils/getSizes';

export const CustomDocument = styled.a`
  max-width: 260px;
  height: 40px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #5a5d68;
  border: ${({ textColor }) => `1px solid ${textColor}`};
  pointer-events: ${({ isUserMessage }) => isUserMessage && 'none'};

  p {
    font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
    color: ${({ textColor }) => `${textColor} !important`};
  }

  svg {
    min-width: 14px !important;
    height: 22px;
    margin-right: 10px;
  }
`;
