/* eslint-disable react/no-danger */
import React from 'react';

import * as S from './styles';
import { treatMessage } from '../../../../utils/treatMessage';

const MessageText = ({ message }) => {
  return (
    <S.Container from={message.from}>
      <p
        dangerouslySetInnerHTML={{
          __html: treatMessage(message.message),
        }}
      />
    </S.Container>
  );
};

export default MessageText;
