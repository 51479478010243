import styled from 'styled-components';
import { getMessageFontSize } from '../../../../utils/getSizes';

export const CarouselWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const CarouselSlider = styled.div`
  position: relative;
  width: 160px;
  overflow: hidden;
  border-radius: 4px;
`;

export const CarouselSliderContent = styled.div`
  display: flex;
  position: relative;
  transition: left 500ms ease-in-out;

  .slide {
    min-width: 160px;
    max-width: 160px;
    border-radius: 4px;
  }

  img,
  span {
    width: 100%;
    height: 88px;
    background-size: cover;
    border-radius: 4px 4px 0px 0px;
  }

  span {
    display: flex;
    height: auto;
  }
`;

export const CarouselCardInfo = styled.div`
  background-color: #f3f5f9;
  display: flex;

  flex-direction: column;
  border-radius: 0px 0px 4px 4px;
  span {
    font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
  }

  & .carousel-padding {
    padding: ${({ hasInfo }) => (hasInfo ? 16 : 0)}px;
  }
`;

export const CustomImage = styled.img`
  object-fit: cover;
  cursor: pointer;
  padding: 0;
`;
