export const getTitleFontSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '16px !important';
    case 'large_size':
      return '18px !important';
    case 'extra_large_size':
      return '22px !important';
    default:
      return '16px !important';
  }
};

export const getMessageFontSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '14px !important';
    case 'large_size':
      return '18px !important';
    case 'extra_large_size':
      return '22px !important';
    default:
      return '14px !important';
  }
};

export const getInputMessageFontSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '16px !important';
    case 'large_size':
      return '18px !important';
    case 'extra_large_size':
      return '22px !important';
    default:
      return '16px !important';
  }
};

export const getFooterFontSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '10px !important';
    case 'large_size':
      return '12px !important';
    case 'extra_large_size':
      return '14px !important';
    default:
      return '10px !important';
  }
};

export const getFooterPading = (fontSize, webchatType) => {
  if (webchatType === 'fullscreen') {
    switch (fontSize) {
      case 'normal_size':
        return '8px 0 0 !important';
      case 'large_size':
        return '8px 0 0 !important';
      case 'extra_large_size':
        return '8px 0 0 !important';
      default:
        return '2px 0 0 !important';
    }
  }
  switch (fontSize) {
    case 'normal_size':
      return '11px !important';
    case 'large_size':
      return '9px !important';
    case 'extra_large_size':
      return '7px !important';
    default:
      return '11px !important';
  }
};

export const getCarouselInfoHeight = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '72px !important';
    case 'large_size':
      return '72px !important';
    case 'extra_large_size':
      return '80px !important';
    default:
      return '72px !important';
  }
};

export const getTypingSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '5px !important';
    case 'large_size':
      return '7px !important';
    case 'extra_large_size':
      return '9px !important';
    default:
      return '5px !important';
  }
};

export const getTimeOnMessageFontSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return '10px !important';
    case 'large_size':
      return '12px !important';
    case 'extra_large_size':
      return '14px !important';
    default:
      return '10px !important';
  }
};

export const getLineBreakSize = (fontSize) => {
  switch (fontSize) {
    case 'normal_size':
      return 18;
    case 'large_size':
      return 21.5;
    case 'extra_large_size':
      return 26;
    default:
      return 18;
  }
};

export const getMessagesHeight = (webchatType, hasFooter) => {
  if (webchatType === 'fullscreen') {
    return '100%';
  }
  return hasFooter ? '308px' : '350px';
};

export const convertNumberToPixel = (number) => {
  if (!Number.isNaN(number)) return `${number}px`;
  return 'auto';
};
