export const getNewMessages = (prevMessages, data, from, status) => {
  const now = new Date();
  const hours = String(now.getHours());
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  const type = data?.type === 'MESSAGE' ? 'TEXT' : data?.type;
  const newArrayMessages = [
    ...prevMessages.filter((message) => message?.type !== 'TYPING'),
    { ...data, type, from, time: currentTime, status },
  ];
  return newArrayMessages;
};

export const formatNewMessage = (data, from, status) => {
  const now = new Date();
  const hours = String(now.getHours());
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  const type = data?.type === 'MESSAGE' ? 'TEXT' : data?.type;
  const newMessage = { ...data, type, from, time: currentTime, status };

  return newMessage;
};

export const getNewHistoricMessages = (prevMessages, data, from, status) => {
  let formattedTime;
  if (data?.when) {
    const date = new Date(data?.when);

    formattedTime = date.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    });
  }
  const now = new Date();
  const hours = String(now.getHours());
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  const type = data?.type === 'MESSAGE' ? 'TEXT' : data?.type;
  const newArrayMessages = prevMessages.filter(
    (message) => message?.type !== 'TYPING'
  );
  newArrayMessages.unshift({
    ...data,
    type,
    from,
    time: data?.when ? formattedTime : currentTime,
    status,
  });
  return newArrayMessages;
};
export const formatMessage = (data, from, status) => {
  const now = new Date();
  const hours = String(now.getHours());
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  const type = data?.type === 'MESSAGE' ? 'TEXT' : data?.type;

  return { ...data, type, from, time: currentTime, status };
};
export const clearTypingMessages = (messages) => {
  const newArrayMessages = [
    ...messages.filter((message) => message?.type !== 'TYPING'),
  ];
  return newArrayMessages;
};

export const createStatus = (webchatType, widgetIsFocused, pageIsOpen) => {
  if (webchatType === 'fullscreen') {
    return pageIsOpen ? 'read' : 'delivered';
  }
  if (webchatType === 'webchat') {
    return widgetIsFocused ? 'read' : 'delivered';
  }
  return 'delivered';
};

export const verifyMessageAlreadyExists = (messages, messageId) => {
  return messages.some((message) => message?.id && message?.id === messageId);
};

export const orderHistoricMessages = (messages) => {
  const groupedMessages = messages.reduce((acc, message) => {
    if (!acc[message.sessionId]) {
      acc[message.sessionId] = [];
    }
    acc[message.sessionId].push(message);
    return acc;
  }, {});

  Object.keys(groupedMessages).forEach((sessionId) => {
    groupedMessages[sessionId].sort(
      (a, b) => new Date(a.when) - new Date(b.when)
    );
  });

  const sortedMessages = [];
  Object.keys(groupedMessages).forEach((sessionId) => {
    sortedMessages.push(...groupedMessages[sessionId]);
  });
  sortedMessages.sort((a, b) => new Date(a.when) - new Date(b.when));
  return sortedMessages;
};
