import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: ${({ from }) => from === 'user' && 'right'};
  }
`;
