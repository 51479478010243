import React, { useState, useEffect, useContext } from 'react';

import { useSettingsContext } from '../../Context/SettingsContext';
import { ChatContext } from '../../Context/ChatContext';

import * as S from './styles';

import {
  setLibrasToTranslate,
  setVoiceToTranslate,
  loadedPlayer,
  hasRybena,
  openPlayer,
  restartPlayer,
  filterMessagesWithoutTranslate,
  translate,
} from '../../utils/rybena';

import HandTranslate from '../../assets/icons/hand-translate.svg';
import VoiceTranslate from '../../assets/icons/translate-voice.svg';
import RestartTranslate from '../../assets/icons/RESTART_TRANSLATE.svg';
import { filterMessagesBot } from '../../utils/filterMessagesBot';
import { messages } from '../../Context/messagesMemory';

/*
  Botões responsáveis por mudar a tradução do player do Rybena ou para Libras ou por Voz
*/
const ButtonsRybena = ({ id }) => {
  const [mainColor, setMainColor] = useState();
  const [typeTranslate, setTypeTranslate] = useState('libras');
  const { bot } = useSettingsContext();

  const { setChangeTranslate } = useContext(ChatContext);

  useEffect(() => {
    if (bot) {
      setMainColor(bot?.channel?.settings?.mainColor);
    }
  }, []);

  const switchTranslate = (typeTranslateParam) => {
    if (
      hasRybena() &&
      !!loadedPlayer() &&
      typeTranslate !== typeTranslateParam
    ) {
      setTypeTranslate(typeTranslateParam);
      openPlayer();
      const messagesFromBot = filterMessagesBot(messages);

      if (messagesFromBot.length) {
        const messagesWithoutTranslate =
          filterMessagesWithoutTranslate(messagesFromBot);

        const messageToTranslate = messagesWithoutTranslate[0];

        setChangeTranslate(true);
        localStorage.setItem('TYPE_TRANSLATE', typeTranslateParam);
        if (typeTranslateParam === 'libras') {
          try {
            setLibrasToTranslate();
          } catch (error) {
            console.log('Error: ', error);
          }
        }

        if (typeTranslateParam === 'voz') {
          try {
            setVoiceToTranslate();
          } catch (error) {
            console.log('Error: ', error);
          }
        }
        if (messagesWithoutTranslate.length) {
          translate(messageToTranslate);
        }
      }
    }
  };

  return (
    <S.Container id={id}>
      <S.Button
        onClick={() => switchTranslate('libras')}
        title="libras"
        mainColor={mainColor}
      >
        <img src={HandTranslate} alt="libras" />
      </S.Button>
      <S.Button
        onClick={() => switchTranslate('voz')}
        title="voz"
        mainColor={mainColor}
      >
        <img src={VoiceTranslate} alt="voice" />
      </S.Button>
      <S.Button
        onClick={() => restartPlayer()}
        title="retomar tradução"
        mainColor={mainColor}
      >
        <img src={RestartTranslate} alt="restart-translate" />
      </S.Button>
    </S.Container>
  );
};

export default ButtonsRybena;
