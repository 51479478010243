import styled from 'styled-components';

export const CarouselActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    outline: none;
    border: none;
    border-radius: 50%;
    color: #5a5d68;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:disabled {
    color: #dadce3;
    cursor: inherit;
  }

  button svg {
    width: 18px;
  }
`;
