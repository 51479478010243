import styled from 'styled-components';
import { getTitleFontSize } from '../../../../utils/getSizes';

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: ${({ hasSound }) => (hasSound ? '200px' : '234px')};

  & img {
    max-width: 48px;
  }

  & h6 {
    margin: 0;
    font-size: ${({ fontSize }) => getTitleFontSize(fontSize)};
    color: ${({ color }) => color} !important;
  }

  &.fullscreen {
    max-width: ${({ hasSound }) => (hasSound ? '74%' : '87%')};
  }
`;
