import React from 'react';
import { useSettingsContext } from '../../../../../Context/SettingsContext';

import * as S from './styles';

const CarouselCardButton = ({ children, ...rest }) => {
  const { fontSize } = useSettingsContext();
  return (
    <S.CarouselCardButton fontSize={fontSize} {...rest} title={children}>
      {children}
    </S.CarouselCardButton>
  );
};

export default CarouselCardButton;
