import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: ${({ isChannelsBar, iconSize, hasChannelsBarBg }) =>
    isChannelsBar
      ? `${!hasChannelsBarBg ? iconSize / 2 : iconSize / 2 + 4}px !important`
      : `${
          !hasChannelsBarBg ? iconSize / 2 - 4 : iconSize / 2 + 4
        }px !important`};

  right: ${({ iconSize, hasChannelsBarBg }) =>
    `${!hasChannelsBarBg ? iconSize + 52 : iconSize + 60}px !important`};

  padding: 4px 16px;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  max-width: 284px;
  min-width: 100px;
  min-height: 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 9999;

  & .popup_message {
    max-width: 187px;

    color: ${({ textColor }) => `${textColor} !important`};
    text-align: left;
    word-break: break-word;
    white-space: pre-wrap;
  }

  & .popup_button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 4px;

    background-color: #f3f5f9 !important;

    width: 24px;
    height: 24px;

    :hover {
      filter: opacity(80%);
    }
  }

  animation: ${({ isOpen }) =>
    isOpen ? 'open-popup 0.5s forwards' : 'close-popup 0.5s forwards'};
  animation-fill-mode: ${({ isOpen }) => (isOpen ? 'forwards' : 'forwards')};
  animation-timing-function: ${({ isOpen }) =>
    isOpen ? 'ease-out' : 'ease-in'};

  @keyframes close-popup {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0.1;
    }

    to {
      opacity: 0;
      width: 0px;
      height: 0px;
      z-index: -1;
      right: 0px;
      position: fixed;
      display: none;
    }
  }

  @keyframes open-popup {
    0% {
      opacity: 0;
      right: 36px;
      position: fixed;
    }

    to {
      opacity: 1;
      bottom: ${({ iconSize, hasChannelsBarBg }) =>
        `${!hasChannelsBarBg ? iconSize / 2 - 4 : iconSize / 2 + 4}px`};
      right: ${({ iconSize, hasChannelsBarBg }) =>
        `${!hasChannelsBarBg ? iconSize + 52 : iconSize + 60}px`};
    }
  }
`;
