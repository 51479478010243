import React from 'react';

import * as S from './styles';

const MessageVideo = ({ message }) => {
  return (
    <S.CustomVideo preload="auto" controls playsinline>
      <track kind="captions" />
      <source src={message.video?.fileUrl} type="video/mp4" />
    </S.CustomVideo>
  );
};

export default MessageVideo;
