import styled from 'styled-components';
import { getInputMessageFontSize } from '../../utils/getSizes';

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: #f3f5f9;
  border-radius: 16px;
  padding: 8px 16px;

  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;

  position: ${({ webchatType }) => webchatType === 'webchat' && 'absolute'};
  bottom: ${({ webchatType, hasFooter }) =>
    webchatType === 'webchat' && hasFooter ? '32px' : '16px'};
  left: ${({ webchatType }) => webchatType === 'webchat' && '16px'};
  right: ${({ webchatType }) => webchatType === 'webchat' && '16px'};

  & .input-message {
    box-sizing: border-box;
    min-height: inherit;
    margin: inherit;
    width: 100% !important;
    height: inherit;
    padding: 2px;
    max-height: ${({ webchatType }) =>
      webchatType === 'webchat' ? '56px !important' : '112px !important'};

    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled ? 'arial' : 'inherit'};
    font-size: ${({ fontSize }) => getInputMessageFontSize(fontSize)};
    color: #5a5d68;
    background-color: #f3f5f9;
    border: none;
    outline: none;
    -ms-tooltip: none;
    align-items: center;
    resize: none;
    cursor: ${({ isOverScrollbar }) => isOverScrollbar && 'default'};
  }

  .webchat_message_actions {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .webchat_message_input_action {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    width: 20px;
    height: 29px;
  }
`;
