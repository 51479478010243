import React from 'react';

import ChatProvider from './Context/ChatContext';
import MessengerProvider from './Context/MessengerContext';
import Routes from './routes/index';

import Webchat from './pages/Webchat';
import ChannelsBar from './pages/ChannelsBar';
import './styles/fonts.css';

function App({ config }) {
  const renderChannel = () => {
    if (config?.isChannelsBar) {
      return <ChannelsBar config={config} />;
    }

    if (config?.webchatType === 'webchat') {
      return <Webchat config={config} />;
    }

    return <Routes />;
  };

  return (
    <ChatProvider>
      <MessengerProvider>
        <div className="code7-app-root">{renderChannel()}</div>
      </MessengerProvider>
    </ChatProvider>
  );
}

export default App;
