import styled from 'styled-components';
import { getMessageFontSize } from '../../utils/getSizes';

export const SuggestionListWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0px;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 777px) {
    width: ${({ hasRybena }) => (hasRybena ? '55%' : '100%')};
    border-radius: 24px 24px 0px 0px;
  }
`;

export const SuggestionButton = styled.div`
  background-color: #f3f5f9;
  border: 1.5px solid #254edb;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 30px;
  max-width: 290px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid blue !important;
  font-family: inherit;
  color: #254edb;
  cursor: pointer;

  span {
    font-size: ${({ fontSize }) => getMessageFontSize(fontSize)};
  }
`;
