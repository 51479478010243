import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import { MenuItem } from './styles';
// import history from '../../services/history';

const MenuItemComponent = ({
  active,
  className,
  to,
  children,
  onClick,
  ...rest
}) => {
  const handleClick = (event) => {
    // if (to) {
    //   history.push(to);
    // }
    onClick?.(event);
  };

  return (
    <MenuItem
      className={cc([className, { active }])}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

MenuItemComponent.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

MenuItemComponent.defaultProps = {
  active: false,
  className: '',
  to: '',
  onClick: null,
};

export default MenuItemComponent;
