import React from 'react';
import cc from 'classcat';
import Typography from '../../../Typography';

import * as S from './styles';

export const HeaderTitle = ({ isFullscreen, icon, title, color, hasSound }) => {
  return (
    <S.HeaderTitle
      className={cc({ fullscreen: isFullscreen })}
      hasSound={hasSound}
      color={color}
    >
      <img src={icon} height="48px" alt={title} />
      <Typography
        color={color}
        variant="h6-normal"
        noWrap
        title={title}
        data-testid="title"
      >
        {title}
      </Typography>
    </S.HeaderTitle>
  );
};
