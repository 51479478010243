import React from 'react';

import * as S from './styles';

const Channel = ({ channel, iconSize, channelsBarIsOpen, background }) => {
  const { icon, name, onClick } = channel;
  const isChannel = name !== 'widgetButton';

  const getImgClassName = () => {
    if (!isChannel) {
      return channelsBarIsOpen ? 'webchat_opened' : 'webchat_closed';
    }
  };

  return (
    <S.Container
      onClick={() => onClick()}
      iconSize={+iconSize}
      isChannel={isChannel}
      background={background}
      id={`channel-${channel?.name}`}
    >
      <img src={icon} alt={name} className={getImgClassName()} />
    </S.Container>
  );
};

export default Channel;
