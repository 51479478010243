import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 45%;
  margin-left: -12px;
`;

export const Button = styled.div`
  background: ${(props) => props.mainColor};
  padding: 5px;
  margin: 3px 0;
  border-radius: 4px;
  cursor: pointer;

  & > img {
    width: 35px;
  }
`;
