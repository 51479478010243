import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 8px 0 8px;
  cursor: pointer;

  position: ${({ isChannel }) => !isChannel && 'fixed'};
  bottom: ${({ isChannel }) => !isChannel && '23px'};

  height: ${({ isChannel, iconSize, background }) =>
    !isChannel && `${background ? iconSize + 6 : iconSize + 6}px`};

  background-color: ${({ isChannel, background }) => !isChannel && background};
  border-radius: ${({ isChannel }) => !isChannel && '50%'};

  img {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
    border-radius: ${({ background }) => background && '50%'};
  }

  .webchat_opened {
    animation: beat1 0.3s;
  }

  .webchat_closed {
    animation: beat2 0.3s;
  }

  @keyframes beat1 {
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes beat2 {
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }
`;
