import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';

import { Container, Loader } from './styles';

const Button = React.forwardRef(
  (
    {
      children,
      className,
      color,
      variant,
      size,
      startIcon,
      endIcon,
      fullWidth,
      width,
      to,
      onClick,
      disabled,
      loading,
      active,
      ...rest
    },
    ref
  ) => {
    const handleClick = (e) => {
      onClick?.(e);
    };

    return (
      <Container
        className={cc([
          size,
          color,
          variant,
          { full: fullWidth, active },
          className,
        ])}
        onClick={handleClick}
        disabled={disabled}
        width={width}
        ref={ref}
        {...rest}
      >
        <div className="btnContainer">
          {loading && <Loader className={cc([color])} variant={variant} />}
          {startIcon && !loading && (
            <span className="icon start-icon">{startIcon}</span>
          )}
          {children}
          {endIcon && <span className="icon end-icon">{endIcon}</span>}
        </div>
      </Container>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'success', 'danger', 'warning']),
  variant: PropTypes.oneOf(['fill', 'outline', 'ghost', 'neutral']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  active: PropTypes.bool,
};

Button.defaultProps = {
  color: 'primary',
  variant: 'fill',
  size: 'medium',
  className: '',
  to: '',
  onClick: () => {},
  startIcon: '',
  endIcon: '',
  fullWidth: false,
  width: '',
  disabled: false,
  loading: false,
  active: false,
};

export default Button;
