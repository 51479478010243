import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Channel from '../Channel';
import Webchat from '../Webchat';

import { useSettingsContext } from '../../../../Context/SettingsContext';
import { useChatContext } from '../../../../Context/ChatContext';

import { getLayoutWidgetParams } from '../../../../utils/getParams';

import * as S from './styles';

const Channels = () => {
  const [channels, setChannels] = useState([]);
  const { bot, customSettings } = useSettingsContext();
  const { channelsBar, channels: channelsBot, channel: webchatChannel } = bot;
  const { settings } = channelsBar;
  const { webchatType, layout } = customSettings;
  const { backgroundBar } = layout;
  const {
    updateScrollToTop,
    setWebchatIsOpen,
    setWebchatIsStarted,
    setChannelsBarIsOpen,
    channelsBarIsOpen,
  } = useChatContext();

  const {
    barOpen: openedChannelsBarIcon,
    barClose: closedChannelsBarIcon,
    iconSize,
  } = settings;

  const handleWebchatWidget = () => {
    if (webchatChannel) {
      setWebchatIsOpen((prevOpen) => !prevOpen);
      setWebchatIsStarted(true);
      updateScrollToTop();
      setChannelsBarIsOpen(false);
    }
  };

  const handleWebchatClick = (settings) => {
    if (!settings) return;
    if (webchatType === 'fullscreen') {
      const layoutParams = getLayoutWidgetParams(layout);
      window.open(`${process.env.REACT_APP_URL}/${bot?._id}${layoutParams}`);
    } else if (webchatType === 'webchat') {
      handleWebchatWidget();
    }
  };

  const handleWhatsAppClick = (settings) => {
    if (settings?.number) {
      window.open(`https://api.whatsapp.com/send?phone=${settings?.number}`);
    }
  };

  const handleEmailClick = (settings) => {
    if (settings?.urlEmail) {
      window.open(`mailto:${settings?.urlEmail}`);
    }
  };

  const handleFacebookClick = (settings) => {
    if (settings?.id) {
      window.open(`https://m.me/${settings?.id}`);
    }
  };

  const handleInstagramClick = (settings) => {
    if (settings?.instagramUserName) {
      window.open(`https://ig.me/m/${settings?.instagramUserName}`);
    }
  };

  const getChannelData = (channel) => {
    const data = channelsBot.find(
      (ch) => ch.channelId.toLowerCase() === channel.toLowerCase()
    );

    const channelSettings = data?.settings;
    switch (channel) {
      case 'WebChat':
        return {
          name: 'WebChat',
          icon: settings?.iconWebchat,
          onClick: () => handleWebchatClick(channelSettings),
        };

      case 'WhatsApp':
        return {
          name: 'WhatsApp',
          icon: settings?.iconWhatsapp,
          onClick: () => handleWhatsAppClick(channelSettings),
        };

      case 'Email':
        return {
          name: 'Email',
          icon: settings?.iconEmail,
          onClick: () => handleEmailClick(settings),
        };

      case 'FacebookMessenger':
        return {
          name: 'FacebookMessenger',
          icon: settings?.iconFacebookMessenger,
          onClick: () => handleFacebookClick(channelSettings),
        };

      case 'Instagram':
        return {
          name: 'Instagram',
          icon: settings?.iconInstagram,
          onClick: () => handleInstagramClick(channelSettings),
        };
      default:
        break;
    }
  };

  const getChannels = () => {
    return channelsBar?.settings?.barChannels?.map((channel) => {
      return getChannelData(channel);
    });
  };

  const handleWidgetClick = () => {
    setChannelsBarIsOpen(!channelsBarIsOpen);
  };

  useEffect(() => {
    if (channelsBar?.settings?.barChannels) {
      setChannels(getChannels());
    }
  }, [bot]);

  const widgetButton = {
    name: 'widgetButton',
    icon: channelsBarIsOpen ? closedChannelsBarIcon : openedChannelsBarIcon,
    onClick: handleWidgetClick,
  };

  return (
    <S.Container>
      <S.ChannelsBar
        iconSize={+iconSize}
        className={channelsBarIsOpen ? 'open' : ''}
        channelsBarIsOpen={channelsBarIsOpen}
        channelsLength={channels.length}
        background={backgroundBar}
      >
        {channels.map((channel) => (
          <CSSTransition
            in={channelsBarIsOpen}
            classNames="icon"
            timeout={300}
            unmountOnExit
            key={channel?.name}
          >
            <Channel
              channel={channel}
              iconSize={iconSize}
              channelsBarIsOpen={channelsBarIsOpen}
              background={backgroundBar}
            />
          </CSSTransition>
        ))}
        <div className="widget-space" />
        {channelsBar.isActive && (
          <Channel
            channel={widgetButton}
            iconSize={+iconSize}
            channelsBarIsOpen={channelsBarIsOpen}
            background={backgroundBar}
          />
        )}
      </S.ChannelsBar>
      {webchatChannel && <Webchat />}
    </S.Container>
  );
};

export default Channels;
