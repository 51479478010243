import styled from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  as: props.as,
}))`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};

  &.body-default {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 150%;
    font-weight: 400;
    color: #5a5d68;
  }

  &.body-default-strong {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 150%;
    font-weight: 600;
    color: #5a5d68;
  }

  &.body-large {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 158%;
    font-weight: 400;
    color: #5a5d68;
  }

  &.body-large-strong {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 158%;
    font-weight: 600;
    color: #5a5d68;
  }

  &.body-small {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 143%;
    font-weight: 400;
    color: #5a5d68;
  }

  &.body-small-strong {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 143%;
    font-weight: 600;
    color: #5a5d68;
  }

  &.button-caption {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 114%;
    font-weight: 600;
    text-transform: uppercase;
    color: black;
  }

  &.button-caption-tight {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 114%;
    font-weight: 500;
    color: black;
  }

  &.caption-default {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 126%;
    font-weight: 400;
    color: #5a5d68;
  }

  &.caption-small {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 10px;
    letter-spacing: 0px;
    line-height: 120%;
    font-weight: 500;
    color: #35373f;
  }

  &.caption-small-tight {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 114%;
    font-weight: 600;
    color: #5a5d68;
  }

  &.h1-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-SemiBold'"};
    font-size: 96px;
    letter-spacing: -2.5px;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h1-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 96px;
    letter-spacing: -2.5px;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.h2-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Bold'"};
    font-size: 60px;
    letter-spacing: -1.5px;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h2-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 60px;
    letter-spacing: -1.5px;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.h3-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Bold'"};
    font-size: 48px;
    letter-spacing: -0.5px;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h3-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 48px;
    letter-spacing: -0.5px;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.h4-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Bold'"};
    font-size: 34px;
    letter-spacing: -0.25px;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h4-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 34px;
    letter-spacing: -0.25px;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.h5-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Bold'"};
    font-size: 24px;
    letter-spacing: -0.05px;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h5-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 24px;
    letter-spacing: -0.05px;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.h6-heavy {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Bold'"};
    font-size: 20px;
    letter-spacing: 0;
    line-height: 120%;
    font-weight: 700;
    color: black;
  }

  &.h6-normal {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Regular'"};
    font-size: 20px;
    letter-spacing: 0;
    line-height: 120%;
    font-weight: 400;
    color: #35373f;
  }

  &.overline-large {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Medium'"};
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 114%;
    font-weight: 500;
    text-transform: uppercase;
    color: black;
  }

  &.overline-small {
    font-family: ${({ fontIsDisabled }) =>
      !fontIsDisabled && "'Archivo', 'Archivo-Medium'"};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 120%;
    font-weight: 600;
    text-transform: uppercase;
    color: #979aa5;
  }
`;
