import React, { createContext, useState, useContext } from 'react';

const SettingsProvider = ({ children }) => {
  const [fontSize, setFontSize] = useState('normal_size');
  const [customSettings, setCustomSettings] = useState({});
  const [bot, setBot] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);

  return (
    <SettingsContext.Provider
      value={{
        fontSize,
        setFontSize,
        bot,
        setBot,
        customSettings,
        setCustomSettings,
        isNotFound,
        setIsNotFound,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
export const SettingsContext = createContext();
export const useSettingsContext = () => useContext(SettingsContext);
