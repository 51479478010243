import messageTypes from '../../constants/messageTypes';
import wrapMessage from './wrapMessage';

import Typing from './components/Typing';
import Text from './components/Text';
import Audio from './components/Audio';
import Image from './components/Image';
import Video from './components/Video';
import Document from './components/Document';
import Carousel from './components/Carousel';
import WebView from './components/WebView';

export const messageComponentByType = (type) => {
  const types = {
    [messageTypes.TYPING]: wrapMessage(Typing),
    [messageTypes.TEXT]: wrapMessage(Text),
    [messageTypes.MESSAGE]: wrapMessage(Text),
    [messageTypes.AUDIO]: wrapMessage(Audio),
    [messageTypes.IMAGE]: wrapMessage(Image),
    [messageTypes.VIDEO]: wrapMessage(Video),
    [messageTypes.DOCUMENT]: wrapMessage(Document),
    [messageTypes.CAROUSEL]: wrapMessage(Carousel),
    [messageTypes.WEBVIEW]: wrapMessage(WebView),
    default: wrapMessage(Text),
  };

  return types[type] || types.default;
};
