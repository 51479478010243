import styled from 'styled-components';

import { getTitleFontSize } from '../../utils/getSizes';

export const Container = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 84px;
  padding: ${({ webchatType }) =>
    webchatType === 'fullscreen' ? '6px 18px' : '16px'};

  label {
    margin: 0;
  }

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ webchatType }) =>
    webchatType === 'fullscreen' ? '0' : '24px 24px 0px 0px'};

  position: relative;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

  @media (min-width: 777px) {
    width: ${({ webchatType }) =>
      webchatType === 'fullscreen' ? '776px' : 'auto'};
    border-radius: 24px 24px 0px 0px;
  }

  & .webchat_header_title {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: ${({ webchatType, hasSound }) =>
      webchatType !== 'fullscreen' && (hasSound ? '200px' : '234px')};

    & img {
      max-width: 48px;
    }

    & h6 {
      margin: 0;
      font-size: ${({ fontSize }) => getTitleFontSize(fontSize)};
      color: ${({ textColor }) => textColor};
    }
  }

  & .webchat_header_actions {
    display: flex;
    align-items: center;
    gap: 12px;

    & svg {
      width: 20px !important;
      height: 20px !important;
      cursor: pointer;
      display: flex;

      & path {
        fill: ${({ textColor }) => textColor};
        width: 20px !important ;
        height: 20px !important;
      }
    }

    & .webchat_header_settings svg {
      cursor: ${({ isDisabledSettings }) =>
        isDisabledSettings && 'not-allowed'};
    }
  }
`;
