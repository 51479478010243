import React, { useEffect, useState } from 'react';

import { useSettingsContext } from '../../Context/SettingsContext';
import { useChatContext } from '../../Context/ChatContext';

import Typography from '../Typography';

import * as S from './styles';

const Popup = ({
  popupIsOpen,
  setPopupIsOpen,
  webchatIsOpen,
  channelsBarIsOpen,
  handleWidgetClick,
}) => {
  const [alreadyClose, setAlreadyClose] = useState(false);
  const { bot, customSettings } = useSettingsContext();
  const { channelsBar, channel } = bot;
  const { layout, isChannelsBar } = customSettings;
  const iconSize = isChannelsBar
    ? channelsBar?.settings?.iconSize
    : channel?.settings.iconSize;
  const backgroundColor = bot?.popupMessage?.mainColor || '#d6e4ff';
  const textColor = bot?.popupMessage?.mainTextColor || '#102693';
  const message = bot?.popupMessage?.text;
  const { setChannelsBarIsOpen } = useChatContext();

  useEffect(() => {
    if (alreadyClose) return;
    setPopupIsOpen(!webchatIsOpen);
    if (!webchatIsOpen && isChannelsBar) {
      setPopupIsOpen(!channelsBarIsOpen);
    }
  }, [webchatIsOpen, channelsBarIsOpen]);

  const handleClick = (e) => {
    if (document.getElementsByClassName('popup_opened').length > 0) {
      if (
        e.tagName === 'svg' ||
        e.tagName === 'path' ||
        e.id === 'close_popup'
      ) {
        setPopupIsOpen(!popupIsOpen);
        setAlreadyClose(true);
      } else {
        setChannelsBarIsOpen(true);
        handleWidgetClick();
      }
    }
  };

  return (
    <S.Container
      backgroundColor={backgroundColor}
      textColor={textColor}
      isOpen={popupIsOpen}
      className={popupIsOpen ? 'popup_opened' : 'popup_closed'}
      iconSize={+iconSize}
      hasChannelsBarBg={isChannelsBar && layout?.backgroundBar}
      isChannelsBar={isChannelsBar}
      onClick={(e) => {
        handleClick(e.target);
      }}
    >
      <Typography className="popup_message" variant="body-small">
        {message}
      </Typography>
      <div
        id="close_popup"
        className="popup_button"
        onClick={(e) => {
          handleClick(e.target);
        }}
      >
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.10156 7.64844C9.45703 7.97656 9.45703 8.55078 9.10156 8.87891C8.9375 9.04297 8.71875 9.125 8.5 9.125C8.25391 9.125 8.03516 9.04297 7.87109 8.87891L5 6.00781L2.10156 8.87891C1.9375 9.04297 1.71875 9.125 1.5 9.125C1.25391 9.125 1.03516 9.04297 0.871094 8.87891C0.515625 8.55078 0.515625 7.97656 0.871094 7.64844L3.74219 4.75L0.871094 1.87891C0.515625 1.55078 0.515625 0.976562 0.871094 0.648438C1.19922 0.292969 1.77344 0.292969 2.10156 0.648438L5 3.51953L7.87109 0.648438C8.19922 0.292969 8.77344 0.292969 9.10156 0.648438C9.45703 0.976562 9.45703 1.55078 9.10156 1.87891L6.23047 4.77734L9.10156 7.64844Z"
            fill="#5A5D68"
          />
        </svg>
      </div>
    </S.Container>
  );
};

export default Popup;
