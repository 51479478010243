import styled from 'styled-components';

export const CustomImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 500px;
  max-width: 400px;
  object-fit: cover;
  cursor: pointer;
`;
