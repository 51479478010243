import React from 'react';

import { useChatContext } from '../../../../Context/ChatContext';
import { useSettingsContext } from '../../../../Context/SettingsContext';

import useStatus from '../../../../Hooks/useStatus';
import useStorage from '../../../../Hooks/useStorage';

import WidgetChat from '../../../../components/WidgetChat';

import * as S from './styles';

const Webchat = () => {
  const { bot } = useSettingsContext();
  const { channelsBar } = bot;
  const { iconSize } = channelsBar?.settings;
  const { webchatIsOpen, setWebchatIsOpen, webchatIsStarted } =
    useChatContext();

  useStatus();
  useStorage();

  return (
    <S.Container iconSize={+iconSize}>
      {webchatIsOpen && (
        <WidgetChat
          webchatIsOpen={webchatIsOpen}
          setWebchatIsOpen={setWebchatIsOpen}
          webchatIsStarted={webchatIsStarted}
        />
      )}
    </S.Container>
  );
};

export default Webchat;
